import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  moduleStep: 'contacts',
  query: '',
  queryIsLoading: true,
  filter: [ 'contacts' ],
  filterIsLoading: true,

  searchLeads: { resultado: [], _meta: { current_page: 0, per_page: 50, total_items: 0, total_pages: 0 } },
  searchLeadsIsLoading: true,

  searchLists: { resultado: [], _meta: { current_page: 0, per_page: 50, total_items: 0, total_pages: 0 } },
  searchListsIsLoading: true,

  searchCampaigns: { resultado: [], _meta: { current_page: 0, per_page: 50, total_items: 0, total_pages: 0 } },
  searchCampaignsIsLoading: true,

  searchTemplates: { resultado: [], _meta: { current_page: 0, per_page: 50, total_items: 0, total_pages: 0 } },
  searchTemplatesIsLoading: true,

  searchLandingPages: { resultado: [], _meta: { current_page: 0, per_page: 50, total_items: 0, total_pages: 0 } },
  searchLandingPagesIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.search };

    case 'SEARCH_MODULE_STEP_FETCHED':
      return { ...state, moduleStep: action.payload };

    case 'CLEAN_SEARCH_QUERY_LIST_FETCHED':
      return { ...state, query: INITIAL_STATE.query, queryIsLoading: true };
    case 'SEARCH_QUERY_FETCHED':
      return { ...state, query: action.payload, queryIsLoading: false };

    case 'CLEAN_SEARCH_FILTER_LIST_FETCHED':
      return { ...state, filter: INITIAL_STATE.filter, filterIsLoading: true };
    case 'SEARCH_FILTER_FETCHED':
      return { ...state, filter: action.payload, filterIsLoading: false };

    case 'CLEAN_SEARCH_LEADS_FETCHED':
      return { ...state, searchLeads: INITIAL_STATE.searchLeads, searchLeadsIsLoading: true };
    case 'SEARCH_LEADS_FETCHED':
      return { ...state, searchLeads: action.payload, searchLeadsIsLoading: false };

    case 'CLEAN_SEARCH_LISTS_FETCHED':
      return { ...state, searchLists: INITIAL_STATE.searchLists, searchListsIsLoading: true };
    case 'SEARCH_LISTS_FETCHED':
      return { ...state, searchLists: action.payload, searchListsIsLoading: false };

    case 'CLEAN_SEARCH_CAMPAIGNS_FETCHED':
      return { ...state, searchCampaigns: INITIAL_STATE.searchCampaigns, searchCampaignsIsLoading: true };
    case 'SEARCH_CAMPAIGNS_FETCHED':
      return { ...state, searchCampaigns: action.payload, searchCampaignsIsLoading: false };

    case 'CLEAN_SEARCH_TEMPLATES_FETCHED':
      return { ...state, searchTemplates: INITIAL_STATE.searchTemplates, searchTemplatesIsLoading: true };
    case 'SEARCH_TEMPLATES_FETCHED':
      return { ...state, searchTemplates: action.payload, searchTemplatesIsLoading: false };

      case 'CLEAN_SEARCH_LANDING_PAGES_FETCHED':
        return { ...state, searchLandingPages: INITIAL_STATE.searchLandingPages, searchLandingPagesIsLoading: true };
      case 'SEARCH_LANDING_PAGES_FETCHED':
        return { ...state, searchLandingPages: action.payload, searchLandingPagesIsLoading: false };

    default:
      return state;
  }
};
