const INITIAL_STATE = {
  listUnsubscribeList: {
    resultado: [],
    _meta: {
      current_page: 0,
      per_page: 50,
      total_items: 0,
      total_pages: 0,
    },
  },
  listUnsubscribeListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_LIST_UNSUBSCRIBE_LIST_FETCHED':
      return { ...state, listUnsubscribeList: INITIAL_STATE.listUnsubscribeList, listUnsubscribeListIsLoading: true };
    case 'LIST_UNSUBSCRIBE_LIST_FETCHED':
      return { ...state, listUnsubscribeList: action.payload, listUnsubscribeListIsLoading: false };
    default:
      return state;
  }
};
