const INITIAL_STATE = {
  statusModalAutomationWhatsAppDashboard: false,
  automationList: {},
  automationListIsLoading: true,
  performance: {
    delivered: 0,
		seen: 0,
		responded: 0,
		lastDelivered: '',
		lastSeen: '',
		lastResponded: '',
		deliveryRate: 0.0,
		seenRate: 0.0,
		respondedRate: 0.0,
    id: 0,
  },
  performanceIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_AUTOMATION_WHATSAPP_DASHBOARD_FETCHED':
      return { ...state, statusModalAutomationWhatsAppDashboard: INITIAL_STATE.statusModalAutomationWhatsAppDashboard };
    case 'OPEN_MODAL_AUTOMATION_WHATSAPP_DASHBOARD_FETCHED':
      return { ...state, statusModalAutomationWhatsAppDashboard: action.payload };

    case 'CLEAN_MODAL_AUTOMATION_WHATSAPP_DASHBOARD_LIST_FETCHED':
      return { ...state, automationList: INITIAL_STATE.automationList, automationListIsLoading: true };
    case 'MODAL_AUTOMATION_WHATSAPP_DASHBOARD_LIST_FETCHED':
      return { ...state, automationList: action.payload, automationListIsLoading: false };


    case 'CLEAN_MODAL_AUTOMATION_WHATSAPP_DASHBOARD_PERFORMANCE_FETCHED':
      return { ...state, performance: INITIAL_STATE.performance, performanceIsLoading: true };
    case 'MODAL_AUTOMATION_WHATSAPP_DASHBOARD_PERFORMANCE_FETCHED':
      return { ...state, performance: action.payload, performanceIsLoading: false };

    default:
      return state;
  }
};
