import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import amber from '@material-ui/core/colors/amber'
import green from '@material-ui/core/colors/green'
import indigo from '@material-ui/core/colors/indigo'
import { makeStyles, useTheme } from '@material-ui/styles'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import ErrorICon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import WarningIcon from '@material-ui/icons/Warning'
import clsx from 'clsx'
import useTimeout from 'hooks/useTimeout'

const useStyles = makeStyles(theme => ({
  card: props => {
    const { variant, color } = props
    const backgroundColor = color
      ? color
      : variant === 'success'
      ? green[600]
      : variant === 'error'
      ? theme.palette.error.dark
      : variant === 'info'
      ? indigo[700]
      : variant === 'warning'
      ? amber[700]
      : theme.palette.grey[800]

    return {
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      zIndex: 1600,
      backgroundColor,
      color: theme.palette.common.white,
    }
  },
  cardContent: {
    display: 'inline-flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'space-between',
    width: '100%',
  },
  cardContentRoot: {
    padding: theme.spacing(1, 2),
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
  },
  container: {
    display: 'inline-flex',
    flex: 1,
    alignItems: 'center',
    alignContent: 'space-between',
    width: '100%',
  },
  variantIcon: {
    marginRight: theme.spacing(1),
  },
  icon: {
    fontSize: 20,
  },
  iconButton: {
    color: 'inherit',
  },
}))

const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorICon,
  info: InfoIcon,
  warning: WarningIcon,
}

const Alert = ({
  open,
  onClose,
  variant = 'default',
  icon,
  color,
  TypographyProps,
  message,
}) => {
  const theme = useTheme()
  const classes = useStyles({ variant, color })
  const standard = theme.transitions.duration.standard
  const Icon = icon ? icon : variant !== 'default' ? variantIcon[variant] : null
  const delay = {
    success: 2000,
    error: 4000,
    info: 3000,
    warning: 3000,
    default: 2000,
  }

  useTimeout(_ => onClose(), delay[variant], [open])

  return (
    <>
      <Slide in={open} direction='up' timeout={standard}>
        <Card square className={classes.card}>
          <CardContent
            classes={{ root: classes.cardContentRoot }}
            className={classes.cardContent}
          >
            <Grid className={classes.container}>
              {Icon && (
                <Icon className={clsx(classes.icon, classes.variantIcon)} />
              )}
              <Typography align='left' {...TypographyProps}>
                {message}
              </Typography>
            </Grid>
            <IconButton onClick={onClose} className={classes.iconButton}>
              <CloseIcon className={classes.icon} />
            </IconButton>
          </CardContent>
        </Card>
      </Slide>
    </>
  )
}

export default Alert
