import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  pageStep: 'model',
  myModelList: '',
  myModelListIsLoading: true,
  publicModelList: '',
  publicModelListIsLoading: true,
  publicClientModelList: '',
  publicClientModelListIsLoading: true,
  tagslList: [],
  clientList: [],
  clientListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.templateCreate };

    case 'TEMPLATE_PAGE_STEP_FETCHED':
      return { ...state, pageStep: action.payload };

    case 'CLEAN_TEMPLATE_MY_MODEL_LIST_FETCHED':
      return { ...state, myModelList: INITIAL_STATE.myModelList, myModelListIsLoading: true };
    case 'TEMPLATE_MY_MODEL_LIST_FETCHED':
      return { ...state, myModelList: action.payload, myModelListIsLoading: false };

    case 'CLEAN_TEMPLATE_PUBLIC_MODEL_LIST_FETCHED':
      return { ...state, publicModelList: INITIAL_STATE.publicModelList, publicModelListIsLoading: true };
    case 'TEMPLATE_PUBLIC_MODEL_LIST_FETCHED':
      return { ...state, publicModelList: action.payload, publicModelListIsLoading: false };

    case 'CLEAN_TEMPLATE_PUBLIC_CLIENT_MODEL_LIST_FETCHED':
      return { ...state, publicClientModelList: INITIAL_STATE.publicClientModelList, publicClientModelListIsLoading: true };
    case 'TEMPLATE_PUBLIC_CLIENT_MODEL_LIST_FETCHED':
      return { ...state, publicClientModelList: action.payload, publicClientModelListIsLoading: false };

    case 'TEMPLATE_CREATE_TAG_LIST_FETCHED':
      return { ...state, tagslList: action.payload };

    case 'CLEAN_TEMPLATE_CLIENT_LIST_FETCHED':
      return { ...state, clientList: INITIAL_STATE.clientList, clientListIsLoading: true };
    case 'TEMPLATE_CLIENT_LIST_FETCHED':
      return { ...state, clientList: action.payload, clientListIsLoading: false };

    default:
      return state;
  }
};
