const INITIAL_STATE = {
  statusModalAutomationDelete: false,
  modalAutomationDeleteList: '',
  modalAutomationDeleteListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_AUTOMATION_DELETE_FETCHED':
      return { ...state, statusModalAutomationDelete: INITIAL_STATE.statusModalAutomationDelete };
    case 'OPEN_MODAL_AUTOMATION_DELETE_FETCHED':
      return { ...state, statusModalAutomationDelete: action.payload };
    case 'CLEAN_MODAL_AUTOMATION_DELETE_LIST_FETCHED':
      return { ...state, modalAutomationDeleteList: INITIAL_STATE.modalAutomationDeleteList, modalAutomationDeleteListIsLoading: true };
    case 'MODAL_AUTOMATION_DELETE_LIST_FETCHED':
      return { ...state, modalAutomationDeleteList: action.payload, modalAutomationDeleteListIsLoading: false };
    default:
      return state;
  }
};
