const INITIAL_STATE = {
  statusModalProfileUserInvitation: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_PROFILE_USER_INVITION_FETCHED':
      return { ...state, statusModalProfileUserInvitation: INITIAL_STATE.statusModalProfileUserInvitation };
    case 'OPEN_MODAL_PROFILE_USER_INVITION_FETCHED':
      return { ...state, statusModalProfileUserInvitation: action.payload };
    default:
      return state;
  }
};
