const INITIAL_STATE = {
  profileMemberList: [],
  profileMemberListIsLoading: true,
  profileInviteList: [],
  profileInviteListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_PROFILE_MEMBER_LIST_FETCHED':
      return { ...state, profileMemberList: INITIAL_STATE.profileMemberList, profileMemberListIsLoading: true };
    case 'PROFILE_MEMBER_LIST_FETCHED':
      return { ...state, profileMemberList: action.payload, profileMemberListIsLoading: false };

    case 'CLEAN_PROFILE_INVITE_LIST_FETCHED':
      return { ...state, profileInviteList: INITIAL_STATE.profileInviteList, profileInviteListIsLoading: true };
    case 'PROFILE_INVITE_LIST_FETCHED':
      return { ...state, profileInviteList: action.payload, profileInviteListIsLoading: false };
    default:
      return state;
  }
};
