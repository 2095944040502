const INITIAL_STATE = {
  campaignDraftList: [],
  campaignDraftListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_CAMPAIGN_DRAFT_LIST_FETCHED':
      return { ...state, campaignDraftList: INITIAL_STATE.campaignDraftList, campaignDraftListIsLoading: true };
    case 'CAMPAIGN_DRAFT_LIST_FETCHED':
      return { ...state, campaignDraftList: action.payload, campaignDraftListIsLoading: false };
    default:
      return state;
  }
};
