const INITIAL_STATE = {
  statusModalAutomationDashboardCsv: false,
  automationDashboardCsvList: '',
  automationDashboardCsvListIsLoading: true,
  automationDashboardCsvConsolidated: '',
  automationDashboardCsvConsolidatedIsLoading: true,
  automationDashboardCsvExported: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_AUTOMATION_DASHBOARD_CSV_FETCHED':
      return { ...state, statusModalAutomationDashboardCsv: INITIAL_STATE.statusModalAutomationDashboardCsv };
    case 'OPEN_MODAL_AUTOMATION_DASHBOARD_CSV_FETCHED':
      return { ...state, statusModalAutomationDashboardCsv: action.payload };

    case 'CLEAN_MODAL_AUTOMATION_DASHBOARD_CSV_LIST_FETCHED':
      return { ...state, automationDashboardCsvList: INITIAL_STATE.automationDashboardCsvList, automationDashboardCsvListIsLoading: true };
    case 'MODAL_AUTOMATION_DASHBOARD_CSV_LIST_FETCHED':
      return { ...state, automationDashboardCsvList: action.payload, automationDashboardCsvListIsLoading: false };

    case 'CLEAN_MODAL_AUTOMATION_DASHBOARD_CSV_CONSOLIDATED_FETCHED':
      return { ...state, automationDashboardCsvConsolidated: INITIAL_STATE.automationDashboardCsvConsolidated, automationDashboardCsvConsolidatedIsLoading: true };
    case 'MODAL_AUTOMATION_DASHBOARD_CSV_CONSOLIDATED_FETCHED':
      return { ...state, automationDashboardCsvConsolidated: action.payload, automationDashboardCsvConsolidatedIsLoading: false };

    case 'MODAL_AUTOMATION_DASHBOARD_CSV_EXPORTED_FETCHED':
      return { ...state, automationDashboardCsvExported: action.payload };
    default:
      return state;
  }
};
