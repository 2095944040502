const INITIAL_STATE = {
  statusModalListUnsubscribeExport: false,
  listUnsubscribeExportList: { data: [], headers: [], filename: ''},
  listUnsubscribeExportListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_LIST_UNSUBSCRIBE_EXPORT_FETCHED':
      return { ...state, statusModalListUnsubscribeExport: INITIAL_STATE.statusModalListUnsubscribeExport };
    case 'OPEN_MODAL_LIST_UNSUBSCRIBE_EXPORT_FETCHED':
      return { ...state, statusModalListUnsubscribeExport: action.payload };

    case 'CLEAN_MODAL_LIST_UNSUBSCRIBE_EXPORT_FETCHED':
      return { ...state, listUnsubscribeExportList: INITIAL_STATE.listUnsubscribeExportList, listUnsubscribeExportListIsLoading: true };
    case 'MODAL_LIST_UNSUBSCRIBE_EXPORT_FETCHED':
      return { ...state, listUnsubscribeExportList: action.payload, listUnsubscribeExportListIsLoading: false };

    default:
      return state;
  }
};
