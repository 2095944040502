const INITIAL_STATE = {
  statusModalSafetyMails: false,
  domainList: [],
  domainListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_SAFETYMAILS_FETCHED':
      return { ...state, statusModalSafetyMails: INITIAL_STATE.statusModalSafetyMails };
    case 'OPEN_MODAL_SAFETYMAILS_FETCHED':
      return { ...state, statusModalSafetyMails: action.payload };

    case 'CLEAN_SAFETY_DOMAIN_LIST_FETCHED':
      return { ...state, domainList: INITIAL_STATE.domainList, domainListIsLoading: true };
    case 'SAFETY_DOMAIN_LIST_FETCHED':
        return { ...state, domainList: action.payload, domainListIsLoading: false };

    default:
      return state;
  }
};
