import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  pageStep: 'model',
  landingPageState: '',
  landingPageDomain: [{ value: '0', label: "mailtop.hp.mktop.me" }],
  landingPageCrmInteration: [],
  landingPageLeadStationInteration: [],
  landingPageCreateList: '',
  landingPageCreateListIsLoading: true,
  landingPageTemplateList: '',
  landingPageTemplateListIsLoading: true,
  landingPageListList: [],
  landingPageListListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.landingPageCreate };

    case 'LANDING_PAGE_PAGE_STEP_FETCHED':
      return { ...state, pageStep: action.payload };

    case 'LANDING_PAGE_STATE_LIST_FETCHED':
      return { ...state, landingPageState: action.payload };

    case 'CLEAN_LANDING_PAGE_CREATE_LIST_FETCHED':
      return { ...state, landingPageCreateList: INITIAL_STATE.landingPageCreateList, landingPageCreateListIsLoading: true };
    case 'LANDING_PAGE_CREATE_LIST_FETCHED':
      return { ...state, landingPageCreateList: action.payload, landingPageCreateListIsLoading: false };

    case 'CLEAN_LANDING_PAGE_TEMPLATES_LIST_FETCHED':
      return { ...state, landingPageTemplateList: INITIAL_STATE.landingPageTemplateList, landingPageTemplateListIsLoading: true };
    case 'LANDING_PAGE_TEMPLATES_LIST_FETCHED':
      return { ...state, landingPageTemplateList: action.payload, landingPageTemplateListIsLoading: false };

    case 'CLEAN_LANDING_PAGE_LIST_LIST_FETCHED':
      return { ...state, landingPageListList: INITIAL_STATE.landingPageListList, landingPageListListIsLoading: true };
    case 'LANDING_PAGE_LIST_LIST_FETCHED':
      return { ...state, landingPageListList: action.payload, landingPageListListIsLoading: false };

    case 'LANDING_PAGE_DOMAIN_LIST_FETCHED':
      return { ...state, landingPageDomain: action.payload };

    case 'LANDING_PAGE_CRM_INTEGRATION_LIST_FETCHED':
      return { ...state, landingPageCrmInteration: action.payload };

    case 'LANDING_PAGE_LEADSTATION_INTEGRATION_LIST_FETCHED':
      return { ...state, landingPageLeadStationInteration: action.payload };

    default:
      return state;
  }
};
