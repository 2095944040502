const INITIAL_STATE = {
  statusModalListRejectionExport: false,
  listRejectionExportedList: '',
  listRejectionExportedListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_LIST_REJECTION_EXPORT_FETCHED':
      return { ...state, statusModalListRejectionExport: INITIAL_STATE.statusModalListRejectionExport };
    case 'OPEN_MODAL_LIST_REJECTION_EXPORT_FETCHED':
      return { ...state, statusModalListRejectionExport: action.payload };

    case 'CLEAN_MODAL_LIST_REJECTION_EXPORTED_FETCHED':
      return { ...state, listRejectionExportedList: INITIAL_STATE.listRejectionExportedList, listRejectionExportedListIsLoading: true };
    case 'MODAL_LIST_REJECTION_EXPORTED_FETCHED':
      return { ...state, listRejectionExportedList: action.payload, listRejectionExportedListIsLoading: false };
    default:
      return state;
  }
};
