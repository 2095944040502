const INITIAL_STATE = {
  statusModalListSafetyEmails: false,
  modalListSafetyEmailsTotal: '',
  modalListSafetyEmailsTotalIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_LIST_SAFETY_EMAILS_FETCHED':
      return { ...state, statusModalListSafetyEmails: INITIAL_STATE.statusModalListSafetyEmails };
    case 'OPEN_MODAL_LIST_SAFETY_EMAILS_FETCHED':
      return { ...state, statusModalListSafetyEmails: action.payload };
    case 'CLEAN_MODAL_LIST_SAFETY_EMAILS_TOTAL_FETCHED':
      return { ...state, modalListSafetyEmailsTotal: INITIAL_STATE.modalListSafetyEmailsTotal, modalListSafetyEmailsTotalIsLoading: true };
    case 'MODAL_LIST_SAFETY_EMAILS_TOTAL_FETCHED':
      return { ...state, modalListSafetyEmailsTotal: action.payload, modalListSafetyEmailsTotalIsLoading: false };
    default:
      return state;
  }
};
