const INITIAL_STATE = {
  concludedList: {
    resultado: [],
    _meta: {
      current_page: 0,
      per_page: 50,
      total_items: 0,
      total_pages: 0,
    },
  },
  concludedListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_AUTOMATION_CONCLUDED_LIST_FETCHED':
      return { ...state, concludedList: INITIAL_STATE.concludedList, concludedListIsLoading: true };
    case 'AUTOMATION_CONCLUDED_LIST_FETCHED':
      return { ...state, concludedList: action.payload, concludedListIsLoading: false };
    default:
      return state;
  }
};
