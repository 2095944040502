const INITIAL_STATE = {
  usageList: {
    resultado: [],
    _meta: {
      current_page: 0,
      per_page: 50,
      total_items: 0,
      total_pages: 0,
    },
  },
  usageListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_BILLING_HISTORY_USAGE_LIST_FETCHED':
      return { ...state, usageList: INITIAL_STATE.usageList, usageListIsLoading: true };
    case 'BILLING_HISTORY_USAGE_LIST_FETCHED':
      return { ...state, usageList: action.payload, usageListIsLoading: false };
    default:
      return state;
  }
};
