import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { reducer as toastr } from 'react-redux-toastr';

// Componentes
import automationPlanReducer from 'src/components/layout/messages/automationPlan/AutomationPlanReducer';
import modalAutomationPlanReducer from 'src/components/modal/modalAutomationPlan/ModalAutomationPlanReducer';
import loaderReducer from 'src/components/loader/LoaderReducer';
import beePluginReducer from 'src/components/beePlugin/BeePluginReducer';
import modalLandingPagePublishReducer from 'src/components/modal/modalLandingPagePublish/ModalLandingPagePublishReducer';
import modalTemplateDeleteReducer from 'src/components/modal/modalTemplateDelete/ModalTemplateDeleteReducer';
import modalLandingPageDeleteReducer from 'src/components/modal/modalLandingPageDelete/ModalLandingPageDeleteReducer';
import modalTemplatePreviewReducer from 'src/components/modal/modalTemplatePreview/ModalTemplatePreviewReducer';
import modalSendTestEmailReducer from 'src/components/modal/modalSendTestEmail/ModalSendTestEmailReducer';
import modalCampaignDeleteReducer from 'src/components/modal/modalCampaignDelete/ModalCampaignDeleteReducer';
import modalCampaignPreviewReducer from 'src/components/modal/modalCampaignPreview/ModalCampaignPreviewReducer';
import modalCampaignCancelSendReducer from 'src/components/modal/modalCampaignCancelSend/ModalCampaignCancelSendReducer';
import modalCampaignSendReducer from 'src/components/modal/modalCampaignSend/ModalCampaignSendReducer';
import modalCampaignSendInvalidReducer from 'src/components/modal/modalCampaignSendInvalid/ModalCampaignSendInvalidReducer';
import modalCampaignSaveSendReducer from 'src/components/modal/modalCampaignSaveSend/ModalCampaignSaveSendReducer';
import modalCampaignDashboardExportReducer from 'src/components/modal/modalCampaignDashboardExport/ModalCampaignDashboardExportReducer';
import modalDomainPendingDmarcConfigurationReducer from 'src/components/modal/modalDomainPendingDmarcConfiguration/ModalDomainPendingDmarcConfigurationReducer';
import modalIntegrationSyonetCrmEditReducer from 'src/components/modal/modalIntegrationSyonetCrmEdit/ModalIntegrationSyonetCrmEditReducer';
import modalIntegrationLeadStationEditReducer from 'src/components/modal/modalIntegrationLeadStationEdit/ModalIntegrationLeadStationEditReducer';
import modalIntegrationWhatsappReducer from 'src/components/modal/modalIntegrationWhatsapp/ModalIntegrationWhatsappReducer';
import modalCampaignDashboardDrilldownReducer from 'src/components/modal/modalCampaignDashboardDrilldown/ModalCampaignDashboardDrilldownReducer';
import modalUserReducer from 'src/components/modal/modalUser/ModalUserReducer';
import modalUserDeleteReducer from 'src/components/modal/modalUserDelete/ModalUserDeleteReducer';
import modalUserUnsubscribeReducer from 'src/components/modal/modalUserUnsubscribe/ModalUserUnsubscribeReducer';
import modalLandingPageAutoUnpublishReducer from 'src/components/modal/modalLandingPageAutoUnpublish/ModalLandingPageAutoUnpublishReducer';
import modalListDeleteReducer from 'src/components/modal/modalListDelete/ModalListDeleteReducer';
import modalListSafetyEmailsReducer from 'src/components/modal/modalListSafetyEmails/ModalListSafetyEmailsReducer';
import modalListReducer from 'src/components/modal/modalList/ModalListReducer';
import modalListUnsubscribeExportReducer from 'src/components/modal/modalListUnsubscribeExport/ModalListUnsubscribeExportReducer';
import modalListExportReducer from 'src/components/modal/modalListExport/ModalListExportReducer';
import modalListImportReducer from 'src/components/modal/modalListImport/ModalListImportReducer';
import modalListRejectionExportReducer from 'src/components/modal/modalListRejectionExport/ModalListRejectionExportReducer';
import modalSegmentReducer from 'src/components/modal/modalSegment/ModalSegmentReducer';
import modalSegmentPreviewReducer from 'src/components/modal/modalSegmentPreview/ModalSegmentPreviewReducer';
import modalSegmentExportReducer from 'src/components/modal/modalSegmentExport/ModalSegmentExportReducer';
import modalSegmentDeleteReducer from 'src/components/modal/modalSegmentDelete/ModalSegmentDeleteReducer';
import modalDomainReducer from 'src/components/modal/modalDomain/ModalDomainReducer';
import modalDomainDeleteReducer from 'src/components/modal/modalDomainDelete/ModalDomainDeleteReducer';
import modalDomainSendEmailReducer from 'src/components/modal/modalDomainSendEmail/ModalDomainSendEmailReducer';
import modalProfileUserDeleteReducer from 'src/components/modal/modalProfileUserDelete/ModalProfileUserDeleteReducer';
import modalProfileUserPasswordReducer from 'src/components/modal/modalProfileUserPassword/ModalProfileUserPasswordReducer';
import modalProfileUserInvitationReducer from 'src/components/modal/modalProfileUserInvitation/ModalProfileUserInvitationReducer';
import modalProfileOrganizationBackupReducer from 'src/components/modal/modalProfileOrganizationBackup/ModalProfileOrganizationBackupReducer';
import modalProfileOrganizationKeyReducer from 'src/components/modal/modalProfileOrganizationKey/ModalProfileOrganizationKeyReducer';
import modalProfileOrganizationUpdateReducer from 'src/components/modal/modalProfileOrganizationUpdate/ModalProfileOrganizationUpdateReducer';
import modalBillingHistoryUsageDetailReducer from 'src/components/modal/modalBillingHistoryUsageDetail/ModalBillingHistoryUsageDetailReducer';
import modalBillingHistoryUsageMoreReducer from 'src/components/modal/modalBillingHistoryUsageMore/ModalBillingHistoryUsageMoreReducer';
import modalBillingPlanPayReducer from 'src/components/modal/modalBillingPlanPay/ModalBillingPlanPayReducer';
import modalBillingInformationEmailReducer from 'src/components/modal/modalBillingInformationEmail/ModalBillingInformationEmailReducer';
import modalBillingInformationMethodReducer from 'src/components/modal/modalBillingInformationMethod/ModalBillingInformationMethodReducer';
import modalListContactDeleteReducer from 'src/components/modal/modalListContactDelete/ModalListContactDeleteReducer';
import modalAutomationActivateReducer from 'src/components/modal/modalAutomationActivate/ModalAutomationActivateReducer';
import modalAutomationConcludeReducer from 'src/components/modal/modalAutomationConclude/ModalAutomationConcludeReducer';
import modalAutomationContactsReducer from 'src/components/modal/modalAutomationContacts/ModalAutomationContactsReducer';
import modalAutomationDeleteReducer from 'src/components/modal/modalAutomationDelete/ModalAutomationDeleteReducer';
import modalAutomationDisableReducer from 'src/components/modal/modalAutomationDisable/ModalAutomationDisableReducer';
import modalAutomationScheduleReducer from 'src/components/modal/modalAutomationSchedule/ModalAutomationScheduleReducer';
import modalAutomationUnscheduleReducer from 'src/components/modal/modalAutomationUnschedule/ModalAutomationUnscheduleReducer';
import modalAutomationDashboardExportReducer from 'src/components/modal/modalAutomationDashboardExport/ModalAutomationDashboardExportReducer';
import modalAutomationCampaingDashboardReducer from 'src/components/modal/modalAutomationCampaingDashboard/ModalAutomationCampaingDashboardReducer';
import modalSafetymailsReducer from 'src/components/modal/modalSafetymails/ModalSafetymailsReducer';
import modalLandingPageSubdomainReducer from 'src/components/modal/modalLandingPageSubdomain/ModalLandingPageSubdomainReducer';
import modalAutomationWhatsAppDashboardReducer from 'src/components/modal/modalAutomationWhatsAppDashboard/ModalAutomationWhatsAppDashboardReducer';
import modalAutomationWhatsAppDashboardDrilldownReducer from 'src/components/modal/modalAutomationWhatsAppDashboardDrilldown/ModalAutomationWhatsAppDashboardDrilldownReducer';
import modalAutomationSmsDashboardReducer from 'src/components/modal/modalAutomationSmsDashboard/ModalAutomationSmsDashboardReducer';
import modalAutomationSmsDashboardDrilldownReducer from 'src/components/modal/modalAutomationSmsDashboardDrilldown/ModalAutomationSmsDashboardDrilldownReducer';
import modalAutomationTransferListReducer from 'src/components/modal/modalAutomationTransferList/ModalAutomationTransferListReducer';

// Módulos
import landingPageReducer from 'src/modules/landingPage/LandingPageReducer';
import landingPageAllReducer from 'src/modules/landingPage/landingPageAll/LandingPageAllReducer';
import landingPagePublishedReducer from 'src/modules/landingPage/landingPagePublished/LandingPagePublishedReducer';
import landingPageUnpublishedReducer from 'src/modules/landingPage/landingPageUnpublished/LandingPageUnpublishedReducer';
import landingPageDraftReducer from 'src/modules/landingPage/landingPageDraft/LandingPageDraftReducer';
import landingPageCreateReducer from 'src/modules/landingPage/landingPageCreate/LandingPageCreateReducer';
import landingPageDashboardReducer from 'src/modules/landingPage/landingPageDashboard/LandingPageDashboardReducer';

import templateReducer from 'src/modules/template/TemplateReducer';
import templateMarketingReducer from 'src/modules/template/templateMarketing/TemplateMarketingReducer';
import templateLandingPageReducer from 'src/modules/template/templateLandingPage/TemplateLandingPageReducer';
import templateCreateReducer from 'src/modules/template/templateCreate/TemplateCreateReducer';

import listReducer from 'src/modules/list/ListReducer';
import listAutomationReducer from 'src/modules/list/listAutomation/ListAutomationReducer';
import listListReducer from 'src/modules/list/listList/ListListReducer';
import listRejectionReducer from 'src/modules/list/listRejection/ListRejectionReducer';
import listUnsubscribeReducer from 'src/modules/list/listUnsubscribe/ListUnsubscribeReducer';
import listSegmentReducer from 'src/modules/list/listSegment/ListSegmentReducer';
import listImportReducer from 'src/modules/list/listImport/ListImportReducer';
import listContactReducer from 'src/modules/list/listContact/ListContactReducer';
import listWebFormReducer from 'src/modules/list/listWebForm/ListWebFormReducer';

import campaignReducer from 'src/modules/campaign/CampaignReducer';
import campaignCompletedReducer from 'src/modules/campaign/campaignCompleted/CampaignCompletedReducer';
import campaignScheduledReducer from 'src/modules/campaign/campaignScheduled/CampaignScheduledReducer';
import campaignDraftReducer from 'src/modules/campaign/campaignDraft/CampaignDraftReducer';
import campaignOtherReducer from 'src/modules/campaign/campaignOther/CampaignOtherReducer';
import campaignCreateReducer from 'src/modules/campaign/campaignCreate/CampaignCreateReducer';
import campaignDashboardReducer from 'src/modules/campaign/campaignDashboard/CampaignDashboardReducer';

import integrationSyonetCrmReducer from 'src/modules/integrationSyonetCrm/IntegrationSyonetCrmReducer';

import integrationSyonetCrmLogReducer from 'src/modules/integrationSyonetCrmLog/IntegrationSyonetCrmLogReducer';

import integrationLeadStationReducer from 'src/modules/integrationLeadStation/IntegrationLeadStationReducer';

import integrationWhatsappReducer from 'src/modules/integrationWhatsapp/IntegrationWhatsappReducer';

import domainReducer from 'src/modules/domain/DomainReducer';

import profileReducer from 'src/modules/profile/ProfileReducer';
import profileOrganizationReducer from 'src/modules/profile/profileOrganzation/ProfileOrganizationReducer';
import profileMemberReducer from 'src/modules/profile/profileMember/ProfileMemberReducer';

import billingReducer from 'src/modules/billing/BillingReducer';
import billingPlanReducer from 'src/modules/billing/billingPlan/BillingPlanReducer';
import billingInformationReducer from 'src/modules/billing/billingInformation/BillingInformationReducer';
import billingHistoryInvoiceReducer from 'src/modules/billing/billingHistoryInvoice/BillingHistoryInvoiceReducer';
import billingHistoryUsageReducer from 'src/modules/billing/billingHistoryUsage/BillingHistoryUsageReducer';

import automationReducer from 'src/modules/automation/AutomationReducer';
import automationActiveReducer from 'src/modules/automation/automationActive/AutomationActiveReducer';
import automationConcludedReducer from 'src/modules/automation/automationConcluded/AutomationConcludedReducer';
import automationInactiveReducer from 'src/modules/automation/automationInactive/AutomationInactiveReducer';
import automationScheduledReducer from 'src/modules/automation/automationScheduled/AutomationScheduledReducer';
import automationEditReducer from 'src/modules/automation/automationEdit/AutomationEditReducer';
import automationDashboardReducer from 'src/modules/automation/automationDashboard/AutomationDashboardReducer';

import searchReducer from 'src/modules/search/SearchReducer';

const appReducers = combineReducers({
  form,
  toastr,
  // Componentes
  automationPlanReducer,
  modalAutomationPlanReducer,
  loaderReducer,
  beePluginReducer,
  modalLandingPagePublishReducer,
  modalTemplateDeleteReducer,
  modalLandingPageDeleteReducer,
  modalTemplatePreviewReducer,
  modalSendTestEmailReducer,
  modalCampaignDeleteReducer,
  modalCampaignPreviewReducer,
  modalCampaignCancelSendReducer,
  modalCampaignSendReducer,
  modalCampaignSendInvalidReducer,
  modalCampaignSaveSendReducer,
  modalCampaignDashboardExportReducer,
  modalDomainPendingDmarcConfigurationReducer,
  modalIntegrationSyonetCrmEditReducer,
  modalIntegrationLeadStationEditReducer,
  modalIntegrationWhatsappReducer,
  modalCampaignDashboardDrilldownReducer,
  modalUserReducer,
  modalUserDeleteReducer,
  modalUserUnsubscribeReducer,
  modalLandingPageAutoUnpublishReducer,
  modalListDeleteReducer,
  modalListSafetyEmailsReducer,
  modalListReducer,
  modalListUnsubscribeExportReducer,
  modalListExportReducer,
  modalListImportReducer,
  modalListRejectionExportReducer,
  modalSegmentReducer,
  modalSegmentPreviewReducer,
  modalSegmentExportReducer,
  modalSegmentDeleteReducer,
  modalDomainReducer,
  modalDomainDeleteReducer,
  modalDomainSendEmailReducer,
  modalProfileUserDeleteReducer,
  modalProfileUserPasswordReducer,
  modalProfileUserInvitationReducer,
  modalProfileOrganizationBackupReducer,
  modalProfileOrganizationKeyReducer,
  modalProfileOrganizationUpdateReducer,
  modalBillingHistoryUsageDetailReducer,
  modalBillingHistoryUsageMoreReducer,
  modalBillingPlanPayReducer,
  modalBillingInformationEmailReducer,
  modalBillingInformationMethodReducer,
  modalListContactDeleteReducer,
  modalAutomationActivateReducer,
  modalAutomationConcludeReducer,
  modalAutomationContactsReducer,
  modalAutomationDeleteReducer,
  modalAutomationDisableReducer,
  modalAutomationScheduleReducer,
  modalAutomationUnscheduleReducer,
  modalAutomationDashboardExportReducer,
  modalAutomationCampaingDashboardReducer,
  modalSafetymailsReducer,
  modalLandingPageSubdomainReducer,
  modalAutomationWhatsAppDashboardReducer,
  modalAutomationWhatsAppDashboardDrilldownReducer,
  modalAutomationSmsDashboardReducer,
  modalAutomationSmsDashboardDrilldownReducer,
  modalAutomationTransferListReducer,
  // Módulos
  landingPageReducer,
  landingPageAllReducer,
  landingPagePublishedReducer,
  landingPageUnpublishedReducer,
  landingPageDraftReducer,
  landingPageCreateReducer,
  landingPageDashboardReducer,
  templateReducer,
  templateMarketingReducer,
  templateLandingPageReducer,
  templateCreateReducer,
  listReducer,
  listAutomationReducer,
  listListReducer,
  listRejectionReducer,
  listUnsubscribeReducer,
  listSegmentReducer,
  listImportReducer,
  listContactReducer,
  listWebFormReducer,
  campaignReducer,
  campaignCompletedReducer,
  campaignScheduledReducer,
  campaignDraftReducer,
  campaignOtherReducer,
  campaignCreateReducer,
  campaignDashboardReducer,
  integrationSyonetCrmReducer,
  integrationSyonetCrmLogReducer,
  integrationLeadStationReducer,
  integrationWhatsappReducer,
  domainReducer,
  profileReducer,
  profileOrganizationReducer,
  profileMemberReducer,
  billingReducer,
  billingPlanReducer,
  billingInformationReducer,
  billingHistoryInvoiceReducer,
  billingHistoryUsageReducer,
  automationReducer,
  automationActiveReducer,
  automationConcludedReducer,
  automationInactiveReducer,
  automationScheduledReducer,
  automationEditReducer,
  automationDashboardReducer,
  searchReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'CLEAN_STATE_FETCHED') {
    state = undefined;
  }
  return appReducers(state, action);
};

export default rootReducer;
