const INITIAL_STATE = {
  campaignOtherList: [],
  campaignOtherListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_CAMPAIGN_OTHER_LIST_FETCHED':
      return { ...state, campaignOtherList: INITIAL_STATE.campaignOtherList, campaignOtherListIsLoading: true };
    case 'CAMPAIGN_OTHER_LIST_FETCHED':
      return { ...state, campaignOtherList: action.payload, campaignOtherListIsLoading: false };
    default:
      return state;
  }
};
