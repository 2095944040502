const INITIAL_STATE = {
  statusModalIntegrationWhatsapp: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_INTEGRATION_WHATSAPP_FETCHED':
      return { ...state, statusModalIntegrationWhatsapp: INITIAL_STATE.statusModalIntegrationWhatsapp };
    case 'MODAL_INTEGRATION_WHATSAPP_FETCHED':
      return { ...state, statusModalIntegrationWhatsapp: action.payload };

    default:
      return state;
  }
};
