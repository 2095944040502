const INITIAL_STATE = {
  statusModalLandingPagePublish: false,
  modalLandingPagePublishList: '',
  modalLandingPagePublishListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_LANDING_PAGE_PUBLISHED_FETCHED':
      return { ...state, statusModalLandingPagePublish: INITIAL_STATE.statusModalLandingPagePublish };
    case 'OPEN_MODAL_LANDING_PAGE_PUBLISHED_FETCHED':
      return { ...state, statusModalLandingPagePublish: action.payload };
    case 'CLEAN_MODAL_LANDING_PAGE_PUBLISHED_LIST_FETCHED':
      return { ...state, modalLandingPagePublishList: INITIAL_STATE.landingPagePublishedList, modalLandingPagePublishListIsLoading: true };
    case 'MODAL_LANDING_PAGE_PUBLISHED_LIST_FETCHED':
      return { ...state, modalLandingPagePublishList: action.payload, modalLandingPagePublishListIsLoading: false };
    default:
      return state;
  }
};
