const INITIAL_STATE = {
  statusModalAutomationTransferList: false,
  contactTransferList: { resultado: [], bloco_automacao_id: '', transfer_automatically: '', destination_list_id: '', _meta: { current_page: 0, per_page: 50, total_items: 0, total_pages: 0 } },
  contactTransferListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case 'CLOSE_MODAL_AUTOMATION_TRANSFER_LIST_FETCHED':
      return { ...state, statusModalAutomationTransferList: INITIAL_STATE.statusModalAutomationTransferList };
    case 'OPEN_MODAL_AUTOMATION_TRANSFER_LIST_FETCHED':
      return { ...state, statusModalAutomationTransferList: action.payload };

    case 'CLEAN_CONTACT_TRANSFER_LIST_FETCHED':
      return { ...state, contactTransferList: INITIAL_STATE.contactTransferList, contactTransferListIsLoading: true };
    case 'CONTACT_TRANSFER_LIST_FETCHED':
      return { ...state, contactTransferList: action.payload, contactTransferListIsLoading: false };

    default:
      return state;
  }
};
