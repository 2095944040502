const INITIAL_STATE = {
  statusModalListExport: false,
  listExportList: '',
  listExportListIsLoading: true,
  listExportedList: '',
  listExportedListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_LIST_EXPORT_FETCHED':
      return { ...state, statusModalListExport: INITIAL_STATE.statusModalListExport };
    case 'OPEN_MODAL_LIST_EXPORT_FETCHED':
      return { ...state, statusModalListExport: action.payload };

    case 'CLEAN_MODAL_LIST_EXPORT_FETCHED':
      return { ...state, listExportList: INITIAL_STATE.listExportList, listExportListIsLoading: true };
    case 'MODAL_LIST_EXPORT_FETCHED':
      return { ...state, listExportList: action.payload, listExportListIsLoading: false };

    case 'CLEAN_MODAL_LIST_EXPORTED_FETCHED':
      return { ...state, listExportedList: INITIAL_STATE.listExportedList, listExportedListIsLoading: true };
    case 'MODAL_LIST_EXPORTED_FETCHED':
      return { ...state, listExportedList: action.payload, listExportedListIsLoading: false };
    default:
      return state;
  }
};
