import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  landingPageList: '',
  landingPageListIsLoading: true,
  landingPageDashboardList: '',
  landingPageDashboardListIsLoading: true,
  landingPageDashboardLeads: '',
  landingPageDashboardLeadsIsLoading: true,
  landingPageDashboardOrigins: '',
  landingPageDashboardOriginsIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.landingPageDashboard };
      case 'CLEAN_LANDING_PAGE_LIST_FETCHED':
        return { ...state, landingPageList: INITIAL_STATE.landingPageList, landingPageListIsLoading: true };
      case 'LANDING_PAGE_LIST_FETCHED':
        return { ...state, landingPageList: action.payload, landingPageListIsLoading: false };

      case 'CLEAN_LANDING_PAGE_DASHBOARD_LIST_FETCHED':
        return { ...state, landingPageDashboardList: INITIAL_STATE.landingPageDashboardList, landingPageDashboardListIsLoading: true };
      case 'LANDING_PAGE_DASHBOARD_LIST_FETCHED':
        return { ...state, landingPageDashboardList: action.payload, landingPageDashboardListIsLoading: false };

      case 'CLEAN_LANDING_PAGE_DASHBOARD_LEADS_FETCHED':
        return { ...state, landingPageDashboardLeads: INITIAL_STATE.landingPageDashboardLeads, landingPageDashboardLeadsIsLoading: true };
      case 'LANDING_PAGE_DASHBOARD_LEADS_FETCHED':
        return { ...state, landingPageDashboardLeads: action.payload, landingPageDashboardLeadsIsLoading: false };

      case 'CLEAN_LANDING_PAGE_DASHBOARD_ORIGINS_FETCHED':
        return { ...state, landingPageDashboardOrigins: INITIAL_STATE.landingPageDashboardOrigins, landingPageDashboardOriginsIsLoading: true };
      case 'LANDING_PAGE_DASHBOARD_ORIGINS_FETCHED':
        return { ...state, landingPageDashboardOrigins: action.payload, landingPageDashboardOriginsIsLoading: false };

    default:
      return state;
  }
};
