const INITIAL_STATE = {
  statusModalBillingInformationEmail: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_BILLING_INFORMATION_EMAIL_FETCHED':
      return { ...state, statusModalBillingInformationEmail: INITIAL_STATE.statusModalBillingInformationEmail };
    case 'OPEN_MODAL_BILLING_INFORMATION_EMAIL_FETCHED':
      return { ...state, statusModalBillingInformationEmail: action.payload };
    default:
      return state;
  }
};
