const INITIAL_STATE = {
  statusModalList: false,
  domainList: [],
  domainListIsLoading: true,
  organization: { automationActivated: false }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_LIST_FETCHED':
      return { ...state, statusModalList: INITIAL_STATE.statusModalList };
    case 'OPEN_MODAL_LIST_FETCHED':
      return { ...state, statusModalList: action.payload };

    case 'CLEAN_DOMAIN_LIST_FETCHED':
      return { ...state, domainList: INITIAL_STATE.domainList, domainListIsLoading: true };
    case 'DOMAIN_LIST_FETCHED':
      return { ...state, domainList: action.payload, domainListIsLoading: false };

    case 'CLEAN_ORGANIZATION_DOMAIN_FETCHED':
      return { ...state, organization: INITIAL_STATE.organization };
    case 'ORGANIZATION_DOMAIN_FETCHED':
      return { ...state, organization: action.payload };

    default:
      return state;
  }
};
