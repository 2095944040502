const INITIAL_STATE = {
  profileOrganizationList: [],
  profileOrganizationListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_PROFILE_ORGANIZATION_LIST_FETCHED':
      return { ...state, profileOrganizationList: INITIAL_STATE.profileOrganizationList, profileOrganizationListIsLoading: true };
    case 'PROFILE_ORGANIZATION_LIST_FETCHED':
      return { ...state, profileOrganizationList: action.payload, profileOrganizationListIsLoading: false };
    default:
      return state;
  }
};
