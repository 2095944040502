import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  pageStep: 'data',
  listAutomationList: [],
  listAutomationListIsLoading: true,
  segmentList: [],
  segmentListIsLoading: true,
  blockTypeList: [],
  blockTypeListIsLoading: true,
  syonetIntegrationList: [],
  syonetIntegrationListIsLoading: true,
  domainList: [],
  domainListIsLoading: true,
  templateList: [],
  templateListIsLoading: true,
  whatsappIntegrationList: [],
  whatsappIntegrationListIsLoading: true,
  channelCrmList: [],
  channelCrmListIsLoading: true,
  templateCrmList: [],
  templateCrmListIsLoading: true,
  variablesCrmList: [],
  variablesCrmListIsLoading: true,
  tagList: [],
  tagListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.automationUpdate };

    case 'AUTOMATION_PAGE_STEP_FETCHED':
      return { ...state, pageStep: action.payload };

    case 'CLEAN_LIST_AUTOMATION_LIST_FETCHED':
      return { ...state, listAutomationList: INITIAL_STATE.listAutomationList, listAutomationListIsLoading: true };
    case 'LIST_AUTOMATION_EDIT_LIST_FETCHED':
      return { ...state, listAutomationList: action.payload, listAutomationListIsLoading: false };

    case 'CLEAN_SEGMENT_LIST_FETCHED':
      return { ...state, segmentList: INITIAL_STATE.segmentList, segmentListIsLoading: true };
    case 'SEGMENT_LIST_FETCHED':
      return { ...state, segmentList: action.payload, segmentListIsLoading: false };

    case 'CLEAN_BLOCK_TYPE_LIST_FETCHED':
      return { ...state, blockTypeList: INITIAL_STATE.blockTypeList, blockTypeListIsLoading: true };
    case 'BLOCK_TYPE_LIST_FETCHED':
      return { ...state, blockTypeList: action.payload, blockTypeListIsLoading: false };

    case 'CLEAN_SYONET_INTEGRATION_LIST_FETCHED':
      return { ...state, syonetIntegrationList: INITIAL_STATE.syonetIntegrationList, syonetIntegrationListIsLoading: true };
    case 'SYONET_INTEGRATION_LIST_FETCHED':
      return { ...state, syonetIntegrationList: action.payload, syonetIntegrationListIsLoading: false };

    case 'CLEAN_AUTOMATION_DOMAIN_LIST_FETCHED':
      return { ...state, domainList: INITIAL_STATE.domainList, domainListIsLoading: true };
    case 'AUTOMATION_DOMAIN_LIST_FETCHED':
      return { ...state, domainList: action.payload, domainListIsLoading: false };

    case 'CLEAN_AUTOMATION_TEMPLATE_LIST_FETCHED':
      return { ...state, templateList: INITIAL_STATE.templateList, templateListIsLoading: true };
    case 'AUTOMATION_TEMPLATE_LIST_FETCHED':
      return { ...state, templateList: action.payload, templateListIsLoading: false };

    case 'CLEAN_AUTOMATION_WHATSAPP_INTEGRATION_LIST_FETCHED':
      return { ...state, whatsappIntegrationList: INITIAL_STATE.whatsappIntegrationList, whatsappIntegrationListIsLoading: true };
    case 'AUTOMATION_WHATSAPP_INTEGRATION_LIST_FETCHED':
      return { ...state, whatsappIntegrationList: action.payload, whatsappIntegrationListIsLoading: false };

    case 'CLEAN_AUTOMATION_WHATSAPP_CHANNEL_CRM_LIST_FETCHED':
      return { ...state, channelCrmList: INITIAL_STATE.channelCrmList, channelCrmListIsLoading: true };
    case 'AUTOMATION_WHATSAPP_CHANNEL_CRM_LIST_FETCHED':
      return { ...state, channelCrmList: action.payload, channelCrmListIsLoading: false };

    case 'CLEAN_AUTOMATION_WHATSAPP_TEMPLATE_CRM_LIST_FETCHED':
      return { ...state, templateCrmList: INITIAL_STATE.templateCrmList, templateCrmListIsLoading: true };
    case 'AUTOMATION_WHATSAPP_TEMPLATE_CRM_LIST_FETCHED':
      return { ...state, templateCrmList: action.payload, templateCrmListIsLoading: false };

    case 'CLEAN_AUTOMATION_WHATSAPP_VARIABLES_CRM_LIST_FETCHED':
      return { ...state, variablesCrmList: INITIAL_STATE.variablesCrmList, variablesCrmListIsLoading: true };
    case 'AUTOMATION_WHATSAPP_VARIABLES_CRM_LIST_FETCHED':
      return { ...state, variablesCrmList: action.payload, variablesCrmListIsLoading: false };

    case 'CLEAN_AUTOMATION_TAG_LIST_FETCHED':
      return { ...state, tagList: INITIAL_STATE.tagList, tagListIsLoading: true };
    case 'AUTOMATION_TAG_LIST_FETCHED':
      return { ...state, tagList: action.payload, tagListIsLoading: false };

    default:
      return state;
  }
};
