const INITIAL_STATE = {
  statusModalCampaignDelete: false,
  modalCampaignDeleteList: '',
  modalCampaignDeleteListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_CAMPAIGN_DELETE_FETCHED':
      return { ...state, statusModalCampaignDelete: INITIAL_STATE.statusModalCampaignDelete };
    case 'OPEN_MODAL_CAMPAIGN_DELETE_FETCHED':
      return { ...state, statusModalCampaignDelete: action.payload };
    case 'CLEAN_MODAL_CAMPAIGN_DELETE_LIST_FETCHED':
      return { ...state, modalCampaignDeleteList: INITIAL_STATE.CampaignDeleteedList, modalCampaignDeleteListIsLoading: true };
    case 'MODAL_CAMPAIGN_DELETE_LIST_FETCHED':
      return { ...state, modalCampaignDeleteList: action.payload, modalCampaignDeleteListIsLoading: false };
    default:
      return state;
  }
};
