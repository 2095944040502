const INITIAL_STATE = {
  statusModalTemplatePreview: false,
  modalTemplatePreviewList: '',
  modalTemplatePreviewListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_TEMPLATE_PREVIEW_FETCHED':
      return { ...state, statusModalTemplatePreview: INITIAL_STATE.statusModalTemplatePreview };
    case 'OPEN_MODAL_TEMPLATE_PREVIEW_FETCHED':
      return { ...state, statusModalTemplatePreview: action.payload };
    case 'CLEAN_MODAL_TEMPLATE_PREVIEW_LIST_FETCHED':
      return { ...state, modalTemplatePreviewList: INITIAL_STATE.modalTemplatePreviewList, modalTemplatePreviewListIsLoading: true };
    case 'MODAL_TEMPLATE_PREVIEW_LIST_FETCHED':
      return { ...state, modalTemplatePreviewList: action.payload, modalTemplatePreviewListIsLoading: false };
    default:
      return state;
  }
};
