import { applyMiddleware, createStore } from "redux";
import { createWrapper } from "next-redux-wrapper";
import Promise from 'redux-promise';
import Multi from 'redux-multi';
import Thunk from 'redux-thunk';

// Estado global
import Reducers from 'src/state/Reducer';

const makeStore = () => {
  const store = applyMiddleware(Multi, Thunk, Promise)(createStore)(Reducers);

  return store;
};

export const Middlewares = createWrapper(makeStore, { debug: false });
