import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  listAutomationList: {
    resultado: [],
    linked: 0,
    linkedIsLoading: true,
    _meta: {
      current_page: 0,
      per_page: 50,
      total_items: 0,
      total_pages: 0,
    },
  },
  listAutomationListIsLoading: true,
  organization: { automationActivated: false }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.listAutomationList };

    case 'CLEAN_LIST_AUTOMATION_LIST_FETCHED':
      return { ...state, listAutomationList: INITIAL_STATE.listAutomationList, listAutomationListIsLoading: true };
    case 'LIST_AUTOMATION_LIST_FETCHED':
      return { ...state, listAutomationList: action.payload, listAutomationListIsLoading: false };

    case 'CLEAN_ORGANIZATION_LIST_FETCHED':
      return { ...state, organization: INITIAL_STATE.organization };
    case 'ORGANIZATION_LIST_FETCHED':
      return { ...state, organization: action.payload };

    case 'CLEAN_LIST_AUTOMATION_LINKED_FETCHED':
      return { ...state, linked: INITIAL_STATE.linked, linkedIsLoading: true };
    case 'LIST_AUTOMATION_LINKED_FETCHED':
      return { ...state, linked: action.payload, linkedIsLoading: false };

    default:
      return state;
  }
};
