const INITIAL_STATE = {
  statusModalCampaignDashboardDrilldown: false,
  modalCampaignDashboardDrilldownList: [],
  modalCampaignDashboardDrilldownListIsLoading: true,
  modalCampaignDashboardListList: [],
  modalCampaignDashboardListListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_CAMPAIGN_DASHBOARD_DRILLDOWN_FETCHED':
      return { ...state, statusModalCampaignDashboardDrilldown: INITIAL_STATE.statusModalCampaignDashboardDrilldown };
    case 'OPEN_MODAL_CAMPAIGN_DASHBOARD_DRILLDOWN_FETCHED':
      return { ...state, statusModalCampaignDashboardDrilldown: action.payload };
    case 'CLEAN_MODAL_CAMPAIGN_DASHBOARD_DRILLDOWN_LIST_FETCHED':
      return { ...state, modalCampaignDashboardDrilldownList: INITIAL_STATE.CampaignDashboardDrilldownedList, modalCampaignDashboardDrilldownListIsLoading: true };
    case 'MODAL_CAMPAIGN_DASHBOARD_DRILLDOWN_LIST_FETCHED':
      return { ...state, modalCampaignDashboardDrilldownList: action.payload, modalCampaignDashboardDrilldownListIsLoading: false };
    case 'CLEAN_MODAL_CAMPAIGN_DASHBOARD_LIST_LIST_FETCHED':
      return { ...state, modalCampaignDashboardListList: INITIAL_STATE.modalCampaignDashboardListList, modalCampaignDashboardListListIsLoading: true };
    case 'MODAL_CAMPAIGN_DASHBOARD_LIST_LIST_FETCHED':
      return { ...state, modalCampaignDashboardListList: action.payload, modalCampaignDashboardListListIsLoading: false };
    default:
      return state;
  }
};
