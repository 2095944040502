import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  moduleStep: 'completed',
  organization: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.campaign };
    case 'CAMPAIGN_MODULE_STEP_FETCHED':
      return { ...state, moduleStep: action.payload };

    case 'ORGANIZATION_FETCHED':
      return { ...state, organization: action.payload };
    default:
      return state;
  }
};
