import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  moduleStep: 'inactive',
  contacts: 0,
  organization: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.automation };

      case 'AUTOMATION_MODULE_STEP_FETCHED':
        return { ...state, moduleStep: action.payload };

      case 'AUTOMATION_CONTACTS_FETCHED':
        return { ...state, contacts: action.payload };

      case 'ORGANIZATION_FETCHED':
      return { ...state, organization: action.payload };

    default:
      return state;
  }
};
