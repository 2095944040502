const INITIAL_STATE = {
  statusModalCampaignSaveSend: false,
  modalCampaignSaveSendList: '',
  modalCampaignSaveSendListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_CAMPAIGN_SAVE_SEND_FETCHED':
      return { ...state, statusModalCampaignSaveSend: INITIAL_STATE.statusModalCampaignSaveSend };
    case 'OPEN_MODAL_CAMPAIGN_SAVE_SEND_FETCHED':
      return { ...state, statusModalCampaignSaveSend: action.payload };
    case 'CLEAN_MODAL_CAMPAIGN_SAVE_SEND_LIST_FETCHED':
      return { ...state, modalCampaignSaveSendList: INITIAL_STATE.modalCampaignSaveSendList, modalCampaignSaveSendListIsLoading: true };
    case 'MODAL_CAMPAIGN_SAVE_SEND_LIST_FETCHED':
      return { ...state, modalCampaignSaveSendList: action.payload, modalCampaignSaveSendListIsLoading: false };
    default:
      return state;
  }
};
