const INITIAL_STATE = {
  statusModalAutomationPlan: false,
  modalAutomationPlanList: '',
  modalAutomationPlanListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_AUTOMATION_PLAN_FETCHED':
      return { ...state, statusModalAutomationPlan: INITIAL_STATE.statusModalAutomationPlan };
    case 'OPEN_MODAL_AUTOMATION_PLAN_FETCHED':
      return { ...state, statusModalAutomationPlan: action.payload };

    case 'CLEAN_MODAL_AUTOMATION_PLAN_LIST_FETCHED':
      return { ...state, modalAutomationPlanList: INITIAL_STATE.modalAutomationPlanList, modalAutomationPlanListIsLoading: true };
    case 'MODAL_AUTOMATION_PLAN_LIST_FETCHED':
      return { ...state, modalAutomationPlanList: action.payload, modalAutomationPlanListIsLoading: false };

    default:
      return state;
  }
};
