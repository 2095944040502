import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  pageStep: 'data',
  listList: [],
  listListIsLoading: true,
  segmentsList: [],
  segmentsListIsLoading: true,
  contactFieldList: [],
  contactFieldListIsLoading: true,
  domainList: [],
  domainListIsLoading: true,
  tagList: [],
  tagListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.campaignCreate };

    case 'CAMPAIGN_PAGE_STEP_FETCHED':
      return { ...state, pageStep: action.payload };

    case 'CLEAN_CAMPAIGN_LIST_LIST_FETCHED':
      return { ...state, listList: INITIAL_STATE.listList, listListIsLoading: true };
    case 'CAMPAIGN_LIST_LIST_FETCHED':
      return { ...state, listList: action.payload, listListIsLoading: false };

    case 'CLEAN_CAMPAIGN_SEGMENT_LIST_FETCHED':
      return { ...state, segmentsList: INITIAL_STATE.segmentsList, segmentsListIsLoading: true };
    case 'CAMPAIGN_SEGMENT_LIST_FETCHED':
      return { ...state, segmentsList: action.payload, segmentsListIsLoading: false };

    case 'CLEAN_CAMPAIGN_CONTACT_FIELD_LIST_FETCHED':
      return { ...state, contactFieldList: INITIAL_STATE.contactFieldList, contactFieldListIsLoading: true };
    case 'CAMPAIGN_CONTACT_FIELD_LIST_FETCHED':
      return { ...state, contactFieldList: action.payload, contactFieldListIsLoading: false };

    case 'CLEAN_CAMPAIGN_DOMAIN_LIST_FETCHED':
      return { ...state, domainList: INITIAL_STATE.domainList, domainListIsLoading: true };
    case 'CAMPAIGN_DOMAIN_LIST_FETCHED':
      return { ...state, domainList: action.payload, domainListIsLoading: false };

    case 'CLEAN_CAMPAIGN_TAG_LIST_FETCHED':
      return { ...state, tagList: INITIAL_STATE.tagList, tagListIsLoading: true };
    case 'CAMPAIGN_TAG_LIST_FETCHED':
      return { ...state, tagList: action.payload, tagListIsLoading: false };

    default:
      return state;
  }
};
