import React from 'react'
import App from 'next/app'
import Router from 'next/router'
import CssBaseline from '@material-ui/core/CssBaseline'
import indigo from '@material-ui/core/colors/indigo'
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'
import { ThemeProvider as ThemeProviderSC } from 'styled-components'
import { ThemeProvider as ThemeProviderMU } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import * as Sentry from '@sentry/node'
import moment from 'moment'
import momentTz from 'moment-timezone'
import NProgress from 'nprogress'
import TagManager from 'react-gtm-module'
import { appWithTranslation, i18n } from 'i18n'

// Helpers
import { getCookies } from 'helpers/cookies'

// Layout
import Head from 'layout/Head'

// Componentes
import Alert from 'components/Alert'
import AlertContext from 'components/AlertContext'
import AppContext from 'components/AppContext'
import 'react-perfect-scrollbar/dist/css/styles.css'

// Estado global
import { Middlewares } from 'src/state/Middlewares';

let theme = createTheme({
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:600px)': {
        minHeight: 48,
      },
    },
  },
  palette: {
    primary: { main: indigo[600] },
    secondary: { main: '#eb545e' },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderRadius: 0,
      },
    },
  },
})
theme = responsiveFontSizes(theme)

Router.events.on('routeChangeStart', () => NProgress.start())

Router.events.on('routeChangeComplete', () => {
  NProgress.done()
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
})

Router.events.on('routeChangeError', () => NProgress.done())

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: process.env.SENTRY_DSN,
})

class CustomApp extends App {
  static async getInitialProps({ Component, ctx }) {
    const { pathname } = ctx
    const jsonCookies = getCookies(ctx)
    const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {}
    const commonProps = { pageProps, pathname, jsonCookies, namespacesRequired: ['common', 'header', 'search', 'footer'] }

    return commonProps
  }

  state = {
    alert: { open: false },
    searching: false,
    navState: {},
  }

  setAlert = (alert) => this.setState({ alert: { ...alert, open: true } })
  closeAlert = () => this.setState((state) => ({ alert: { ...state.alert, open: false } }))
  setSearching = (searching) => this.setState({ searching })
  setNavState = (navState) => this.setState({ navState })

  componentDidMount() {
    const { jsonCookies } = this.props
    const { tz } = jsonCookies
    const { language } = i18n
    const jssStyles = document.querySelector('#jss-server-side')

    language === 'pt' ? moment.locale('pt-br') : moment.locale(language)
    momentTz.tz.setDefault(tz)

    if (jssStyles && jssStyles.parentNode) jssStyles.parentNode.removeChild(jssStyles)

    if (process.env.NODE_ENV === 'production') TagManager.initialize({ gtmId: process.env.GTM_ID })
  }

  render() {
    const { Component, pageProps, pathname, jsonCookies } = this.props
    const { err } = this.props
    const modifiedPageProps = { ...pageProps, err }
    const { alert, searching, navState } = this.state

    if (process.browser) {
      const validate = document.getElementById('chat_movidesk')

      if (!validate) {
        const script = document.createElement('script')
        script.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js'
        script.async = true
        script.id = 'chat_movidesk'

        // Se estiver logado, adiciona o chat
        if (modifiedPageProps.user) document.body.appendChild(script)
      }

      // Remove o chat ao fazer logoff
      if (!modifiedPageProps.user) {
        const chatElem = document.getElementById('chat_movidesk')
        if (chatElem) chatElem.remove()
        const appElem = document.getElementById('md-app-widget')
        if (appElem) appElem.remove()
      }
    }

    return (
      <AppContext.Provider value={{ pathname, cookies: jsonCookies, setSearching: this.setSearching, searching, setNavState: this.setNavState, navState }}>
        <Head />
          <ThemeProviderMU theme={theme}>
            <ThemeProviderSC theme={theme}>
              <CssBaseline />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <AlertContext.Provider value={{ setAlert: this.setAlert }}>
                  <Alert onClose={this.closeAlert} {...alert} />
                  <Component {...modifiedPageProps} />
                </AlertContext.Provider>
              </MuiPickersUtilsProvider>
            </ThemeProviderSC>
          </ThemeProviderMU>
        <style jsx global>{`
          .bold {
            font-weight: 500;
          }

          .action-area {
            text-align: right;
          }

          .action-area > * + * {
            margin-left: 8px;
          }
        `}</style>
      </AppContext.Provider>
    )
  }
}

export default  Middlewares.withRedux(appWithTranslation(CustomApp))
