const INITIAL_STATE = {
  automationPlan: {
    automationPlanUpgraded: { next_plan: '' },
    automationExceedingPlan: false,
    organization: { nome: '' },
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_AUTOMATION_PLAN_FETCHED':
      return { ...state, automationPlan: INITIAL_STATE.automationPlan };
    case 'AUTOMATION_PLAN_FETCHED':
      return { ...state, automationPlan: action.payload };

    default:
      return state;
  }
};
