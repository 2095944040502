const INITIAL_STATE = {
  statusModalAutomationWhatsAppDrilldown: false,
  modalAutomationWhatsAppDrilldownList: {},
  modalAutomationWhatsAppDrilldownListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_AUTOMATION_WHATSAPP_DRILLDOWN_FETCHED':
      return { ...state, statusModalAutomationWhatsAppDrilldown: INITIAL_STATE.statusModalAutomationWhatsAppDrilldown };
    case 'OPEN_MODAL_AUTOMATION_WHATSAPP_DRILLDOWN_FETCHED':
      return { ...state, statusModalAutomationWhatsAppDrilldown: action.payload };

    case 'CLEAN_MODAL_AUTOMATION_WHATSAPP_DRILLDOWN_LIST_FETCHED':
      return { ...state, modalAutomationWhatsAppDrilldownList: INITIAL_STATE.AutomationWhatsAppDrilldownedList, modalAutomationWhatsAppDrilldownListIsLoading: true };
    case 'MODAL_AUTOMATION_WHATSAPP_DRILLDOWN_LIST_FETCHED':
      return { ...state, modalAutomationWhatsAppDrilldownList: action.payload, modalAutomationWhatsAppDrilldownListIsLoading: false };

    default:
      return state;
  }
};
