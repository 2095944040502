const INITIAL_STATE = {
  statusBillingPlanPay: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_BILLING_PLAN_PAY_FETCHED':
      return { ...state, statusBillingPlanPay: INITIAL_STATE.statusBillingPlanPay };
    case 'OPEN_MODAL_BILLING_PLAN_PAY_FETCHED':
      return { ...state, statusBillingPlanPay: action.payload };
    default:
      return state;
  }
};
