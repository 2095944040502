import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  listWebFormContactList: [],
  listWebFormContactListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.listWebForm };
    case 'CLEAN_LIST_WEB_FORM_CONTACT_FETCHED':
      return { ...state, listWebFormContactList: INITIAL_STATE.listWebFormContactList, listWebFormContactListIsLoading: true };
    case 'LIST_WEB_FORM_CONTACT_FETCHED':
      return { ...state, listWebFormContactList: action.payload, listWebFormContactListIsLoading: false };
    default:
      return state;
  }
};
