const INITIAL_STATE = {
  statusModalAutomationActivate: false,
  modalAutomationActivateList: '',
  modalAutomationActivateListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_AUTOMATION_ACTIVATE_FETCHED':
      return { ...state, statusModalAutomationActivate: INITIAL_STATE.statusModalAutomationActivate };
    case 'OPEN_MODAL_AUTOMATION_ACTIVATE_FETCHED':
      return { ...state, statusModalAutomationActivate: action.payload };

    case 'CLEAN_MODAL_AUTOMATION_ACTIVATE_LIST_FETCHED':
      return { ...state, modalAutomationActivateList: INITIAL_STATE.modalAutomationActivateList, modalAutomationActivateListIsLoading: true };
    case 'MODAL_AUTOMATION_ACTIVATE_LIST_FETCHED':
      return { ...state, modalAutomationActivateList: action.payload, modalAutomationActivateListIsLoading: false };

    default:
      return state;
  }
};
