const INITIAL_STATE = {
  statusModalIntegrationLeadStationEdit: false,
  userList: [],
  userListIsLoading: true,
  sourceList: [],
  sourceListIsLoading: true,
  funnelList: [],
  funnelListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_INTEGRATION_LEADSTATION_EDIT_FETCHED':
      return { ...state, statusModalIntegrationLeadStationEdit: INITIAL_STATE.statusModalIntegrationLeadStationEdit };
    case 'MODAL_INTEGRATION_LEADSTATION_EDIT_FETCHED':
      return { ...state, statusModalIntegrationLeadStationEdit: action.payload };

    case 'CLEAN_USER_INTEGRATION_LEADSTATION_EDIT_FETCHED':
      return { ...state, userList: INITIAL_STATE.userList, userListIsLoading: true };
    case 'USER_INTEGRATION_LEADSTATION_EDIT_FETCHED':
      return { ...state, userList: action.payload, userListIsLoading: false };

    case 'CLEAN_SOURCE_INTEGRATION_LEADSTATION_EDIT_FETCHED':
      return { ...state, sourceList: INITIAL_STATE.sourceList, sourceListIsLoading: true };
    case 'SOURCE_INTEGRATION_LEADSTATION_EDIT_FETCHED':
      return { ...state, sourceList: action.payload, sourceListIsLoading: false };

    case 'CLEAN_FUNNEL_INTEGRATION_LEADSTATION_EDIT_FETCHED':
      return { ...state, funnelList: INITIAL_STATE.funnelList, funnelListIsLoading: true };
    case 'FUNNEL_INTEGRATION_LEADSTATION_EDIT_FETCHED':
      return { ...state, funnelList: action.payload, funnelListIsLoading: false };

    default:
      return state;
  }
};
