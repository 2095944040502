import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  beePlugin: '',
  hashIdOrganization: '',
  type: '',
  selected: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.beePlugin };

    case 'CLEAN_BEE_PLUGIN_FETCHED':
      return { ...state, beePlugin: INITIAL_STATE.beePlugin };
    case 'BEE_PLUGIN_FETCHED':
      return { ...state, beePlugin: action.payload };

    case 'BEE_HASHID_LIST_FETCHED':
      return { ...state, hashIdOrganization: action.payload };

    case 'BEE_TYPE_LIST_FETCHED':
      return { ...state, type: action.payload };

    case 'BEE_SELECTED_LIST_FETCHED':
      return { ...state, selected: action.payload };

    default:
      return state;
  }
};
