const INITIAL_STATE = {
  statusModalIntegrationSyonetCrmEdit: false,
  companyList: [],
  companyListIsLoading: true,
  eventGroupList: [],
  eventGroupListIsLoading: true,
  eventTypeList: [],
  eventTypeListIsLoading: true,
  mediaList: [],
  mediaListIsLoading: true,
  sourceList: [],
  sourceListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_INTEGRATION_SYONET_CRM_EDIT_FETCHED':
      return { ...state, statusModalIntegrationSyonetCrmEdit: INITIAL_STATE.statusModalIntegrationSyonetCrmEdit };
    case 'MODAL_INTEGRATION_SYONET_CRM_EDIT_FETCHED':
      return { ...state, statusModalIntegrationSyonetCrmEdit: action.payload };

    case 'CLEAN_COMPANY_INTEGRATION_SYONET_CRM_EDIT_FETCHED':
      return { ...state, companyList: INITIAL_STATE.companyList, companyListIsLoading: true };
    case 'COMPANY_INTEGRATION_SYONET_CRM_EDIT_FETCHED':
      return { ...state, companyList: action.payload, companyListIsLoading: false };

    case 'CLEAN_EVENTO_GROUP_INTEGRATION_SYONET_CRM_EDIT_FETCHED':
      return { ...state, eventGroupList: INITIAL_STATE.eventGroupList, eventGroupListIsLoading: true };
    case 'EVENTO_GROUP_INTEGRATION_SYONET_CRM_EDIT_FETCHED':
      return { ...state, eventGroupList: action.payload, eventGroupListIsLoading: false };

    case 'CLEAN_EVENTO_TYPE_INTEGRATION_SYONET_CRM_EDIT_FETCHED':
      return { ...state, eventTypeList: INITIAL_STATE.eventTypeList, eventTypeListIsLoading: true };
    case 'EVENTO_TYPE_INTEGRATION_SYONET_CRM_EDIT_FETCHED':
      return { ...state, eventTypeList: action.payload, eventTypeListIsLoading: false };

    case 'CLEAN_MEDIA_INTEGRATION_SYONET_CRM_EDIT_FETCHED':
      return { ...state, mediaList: INITIAL_STATE.mediaList, mediaListIsLoading: true };
    case 'MEDIA_INTEGRATION_SYONET_CRM_EDIT_FETCHED':
      return { ...state, mediaList: action.payload, mediaListIsLoading: false };

    case 'CLEAN_SOURCE_INTEGRATION_SYONET_CRM_EDIT_FETCHED':
      return { ...state, sourceList: INITIAL_STATE.sourceList, sourceListIsLoading: true };
    case 'SOURCE_INTEGRATION_SYONET_CRM_EDIT_FETCHED':
      return { ...state, sourceList: action.payload, sourceListIsLoading: false };

    default:
      return state;
  }
};
