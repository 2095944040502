import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  pageStep: 'list',
  segmentList: {
    resultado: [],
    _meta: {
      current_page: 0,
      per_page: 50,
      total_items: 0,
      total_pages: 0,
    },
  },
  segmentListIsLoading: true,
  segmentDetailList: {
    contacts: {
      resultado: [],
      _meta: {
        current_page: 0,
        per_page: 50,
        total_items: 0,
        total_pages: 0,
      },
    }
  },
  segmentDetailListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.listSegment };
    case 'SEGMENT_PAGE_STEP_FETCHED':
      return { ...state, pageStep: action.payload };
    case 'CLEAN_SEGMENT_LIST_FETCHED':
      return { ...state, segmentList: INITIAL_STATE.segmentList, segmentListIsLoading: true };
    case 'SEGMENT_LIST_FETCHED':
      return { ...state, segmentList: action.payload, segmentListIsLoading: false };
    case 'CLEAN_SEGMENT_DETAIL_LIST_FETCHED':
      return { ...state, segmentDetailList: INITIAL_STATE.segmentDetailList, segmentDetailListIsLoading: true };
    case 'SEGMENT_DETAIL_LIST_FETCHED':
      return { ...state, segmentDetailList: action.payload, segmentDetailListIsLoading: false };
    default:
      return state;
  }
};
