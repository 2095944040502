const INITIAL_STATE = {
  statusModalSegmentPreview: false,
  conditionPreviewList: [],
  conditionPreviewListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_SEGMENT_PREVIEW_FETCHED':
      return { ...state, statusModalSegmentPreview: INITIAL_STATE.statusModalSegmentPreview };
    case 'OPEN_MODAL_SEGMENT_PREVIEW_FETCHED':
      return { ...state, statusModalSegmentPreview: action.payload };

    case 'CLEAN_CONDITION_PREVIEW_LIST_FETCHED':
      return { ...state, conditionPreviewList: INITIAL_STATE.conditionPreviewList, conditionPreviewListIsLoading: true };
    case 'CONDITION_PREVIEW_LIST_FETCHED':
      return { ...state, conditionPreviewList: action.payload, conditionPreviewListIsLoading: false };

    default:
      return state;
  }
};
