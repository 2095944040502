const INITIAL_STATE = {
  statusModalDomainDelete: false,
  modalDomainDeleteList: '',
  modalDomainDeleteListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_DOMAIN_DELETE_FETCHED':
      return { ...state, statusModalDomainDelete: INITIAL_STATE.statusModalDomainDelete };
    case 'OPEN_MODAL_DOMAIN_DELETE_FETCHED':
      return { ...state, statusModalDomainDelete: action.payload };
    case 'CLEAN_MODAL_DOMAIN_DELETE_LIST_FETCHED':
      return { ...state, modalDomainDeleteList: INITIAL_STATE.modalDomainDeleteList, modalDomainDeleteListIsLoading: true };
    case 'MODAL_DOMAIN_DELETE_LIST_FETCHED':
      return { ...state, modalDomainDeleteList: action.payload, modalDomainDeleteListIsLoading: false };
    default:
      return state;
  }
};
