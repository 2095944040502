const INITIAL_STATE = {
  statusModalCampaignSendInvalid: false,
  modalCampaignSendInvalidList: '',
  modalCampaignSendInvalidListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_CAMPAIGN_SEND_INVALID_FETCHED':
      return { ...state, statusModalCampaignSendInvalid: INITIAL_STATE.statusModalCampaignSendInvalid };
    case 'OPEN_MODAL_CAMPAIGN_SEND_INVALID_FETCHED':
      return { ...state, statusModalCampaignSendInvalid: action.payload };

    case 'CLEAN_MODAL_CAMPAIGN_SEND_INVALID_LIST_FETCHED':
      return { ...state, modalCampaignSendInvalidList: INITIAL_STATE.modalCampaignSendInvalidList, modalCampaignSendInvalidListIsLoading: true };
    case 'MODAL_CAMPAIGN_SEND_INVALID_LIST_FETCHED':
      return { ...state, modalCampaignSendInvalidList: action.payload, modalCampaignSendInvalidListIsLoading: false };

    default:
      return state;
  }
};
