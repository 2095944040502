const INITIAL_STATE = {
  statusModalAutomationUnschedule: false,
  modalAutomationUnscheduleList: '',
  modalAutomationUnscheduleListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_AUTOMATION_UNSCHEDULE_FETCHED':
      return { ...state, statusModalAutomationUnschedule: INITIAL_STATE.statusModalAutomationUnschedule };
    case 'OPEN_MODAL_AUTOMATION_UNSCHEDULE_FETCHED':
      return { ...state, statusModalAutomationUnschedule: action.payload };
    case 'CLEAN_MODAL_AUTOMATION_UNSCHEDULE_LIST_FETCHED':
      return { ...state, modalAutomationUnscheduleList: INITIAL_STATE.modalAutomationUnscheduleList, modalAutomationUnscheduleListIsLoading: true };
    case 'MODAL_AUTOMATION_UNSCHEDULE_LIST_FETCHED':
      return { ...state, modalAutomationUnscheduleList: action.payload, modalAutomationUnscheduleListIsLoading: false };
    default:
      return state;
  }
};
