const INITIAL_STATE = {
  statusModalAutomationContacts: false,
  modalAutomationContactsList: {},
  modalAutomationContactsListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_AUTOMATION_CONTACTS_FETCHED':
      return { ...state, statusModalAutomationContacts: INITIAL_STATE.statusModalAutomationContacts };
    case 'OPEN_MODAL_AUTOMATION_CONTACTS_FETCHED':
      return { ...state, statusModalAutomationContacts: action.payload };

    case 'CLEAN_MODAL_AUTOMATION_CONTACTS_LIST_FETCHED':
      return { ...state, modalAutomationContactsList: INITIAL_STATE.AutomationContactsedList, modalAutomationContactsListIsLoading: true };
    case 'MODAL_AUTOMATION_CONTACTS_LIST_FETCHED':
      return { ...state, modalAutomationContactsList: action.payload, modalAutomationContactsListIsLoading: false };

    default:
      return state;
  }
};
