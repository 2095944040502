const INITIAL_STATE = {
  statusModalSegment: false,
  conditionList: [],
  conditionListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_SEGMENT_FETCHED':
      return { ...state, statusModalSegment: INITIAL_STATE.statusModalSegment };
    case 'OPEN_MODAL_SEGMENT_FETCHED':
      return { ...state, statusModalSegment: action.payload };
    case 'CLEAN_CONDITION_LIST_FETCHED':
      return { ...state, conditionList: INITIAL_STATE.conditionList, conditionListIsLoading: true };
    case 'CONDITION_LIST_FETCHED':
      return { ...state, conditionList: action.payload, conditionListIsLoading: false };
    default:
      return state;
  }
};
