const INITIAL_STATE = {
  invoiceList: {
    resultado: [],
    _meta: {
      current_page: 0,
      per_page: 50,
      total_items: 0,
      total_pages: 0,
    },
  },
  invoiceListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_BILLING_HISTORY_INVOICE_LIST_FETCHED':
      return { ...state, invoiceList: INITIAL_STATE.invoiceList, invoiceListIsLoading: true };
    case 'BILLING_HISTORY_INVOICE_LIST_FETCHED':
      return { ...state, invoiceList: action.payload, invoiceListIsLoading: false };
    default:
      return state;
  }
};
