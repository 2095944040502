const INITIAL_STATE = {
  statusModalProfileUserDelete: false,
  modalProfileUserDeleteProfileUser: '',
  modalProfileUserDeleteProfileUserIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_PROFILE_USER_DELETE_FETCHED':
      return { ...state, statusModalProfileUserDelete: INITIAL_STATE.statusModalProfileUserDelete };
    case 'OPEN_MODAL_PROFILE_USER_DELETE_FETCHED':
      return { ...state, statusModalProfileUserDelete: action.payload };
    case 'CLEAN_MODAL_PROFILE_USER_DELETE_LIST_FETCHED':
      return { ...state, modalProfileUserDeleteProfileUser: INITIAL_STATE.modalProfileUserDeleteProfileUser, modalProfileUserDeleteProfileUserIsLoading: true };
    case 'MODAL_PROFILE_USER_DELETE_LIST_FETCHED':
      return { ...state, modalProfileUserDeleteProfileUser: action.payload, modalProfileUserDeleteProfileUserIsLoading: false };
    default:
      return state;
  }
};
