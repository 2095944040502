import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  landingPagePublishedList: {
    resultado: [],
    _meta: {
      current_page: 0,
      per_page: 50,
      total_items: 0,
      total_pages: 0,
    },
  },
  landingPagePublishedListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.landingPagePublished };
    case 'CLEAN_LANDING_PAGE_PUBLISHED_LIST_FETCHED':
      return { ...state, landingPagePublishedList: INITIAL_STATE.landingPagePublishedList, landingPagePublishedListIsLoading: true };
    case 'LANDING_PAGE_PUBLISHED_LIST_FETCHED':
      return { ...state, landingPagePublishedList: action.payload, landingPagePublishedListIsLoading: false };
    default:
      return state;
  }
};
