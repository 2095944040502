const INITIAL_STATE = {
  statusModalUserUnsubscribe: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_USER_UNSUBSCRIBE_FETCHED':
      return { ...state, statusModalUserUnsubscribe: INITIAL_STATE.statusModalUserUnsubscribe };
    case 'OPEN_MODAL_USER_UNSUBSCRIBE_FETCHED':
      return { ...state, statusModalUserUnsubscribe: action.payload };

    default:
      return state;
  }
};
