const INITIAL_STATE = {
  statusModalBillingHistoryUsageDetail: false,
  items: {
    type: '',
    item: '',
    itemsList: [],
    _meta: {
      current_page: 1,
      per_page: 50,
      total_items: 0,
      total_pages: 1,
    }
  },
  itemsIsLoading: true,
  itemExport: { data: [], headers: []},
  itemExportIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_BILLING_HISTORY_USAGE_DETAIL_FETCHED':
      return { ...state, statusModalBillingHistoryUsageDetail: INITIAL_STATE.statusModalBillingHistoryUsageMore };
    case 'OPEN_MODAL_BILLING_HISTORY_USAGE_DETAIL_FETCHED':
      return { ...state, statusModalBillingHistoryUsageDetail: action.payload };

    case 'CLEAN_MODAL_BILLING_HISTORY_USAGE_DETAIL_LIST_FETCHED':
      return { ...state, items: INITIAL_STATE.items, itemsIsLoading: true };
    case 'MODAL_BILLING_HISTORY_USAGE_DETAIL_LIST_FETCHED':
      return { ...state, items: action.payload, itemsIsLoading: false };

    case 'CLEAN_MODAL_BILLING_HISTORY_USAGE_DETAIL_EXPORT_FETCHED':
      return { ...state, itemExport: INITIAL_STATE.itemExport, itemExportIsLoading: true };
    case 'MODAL_BILLING_HISTORY_USAGE_DETAIL_EXPORT_FETCHED':
      return { ...state, itemExport: action.payload, itemExportIsLoading: false };

    default:
      return state;
  }
};
