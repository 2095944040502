const INITIAL_STATE = {
  invoicingList: '',
  invoicingListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_BILLING_INFORMATION_INVOICING_LIST_FETCHED':
      return { ...state, invoicingList: INITIAL_STATE.invoicingList, invoicingListIsLoading: true };
    case 'BILLING_INFORMATION_INVOICING_LIST_FETCHED':
      return { ...state, invoicingList: action.payload, invoicingListIsLoading: false };
    default:
      return state;
  }
};
