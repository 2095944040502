const INITIAL_STATE = {
  statusModalDomainSendEmail: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_DOMAIN_SEND_EMAIL_FETCHED':
      return { ...state, statusModalDomainSendEmail: INITIAL_STATE.statusModalDomainSendEmail };
    case 'OPEN_MODAL_DOMAIN_SEND_EMAIL_FETCHED':
      return { ...state, statusModalDomainSendEmail: action.payload };
    default:
      return state;
  }
};
