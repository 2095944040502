import { useEffect } from 'react'

const useTimeout = (callback, delay, deps = []) => {
  useEffect(_ => {
    const timer = setTimeout(callback, delay)

    return _ => clearTimeout(timer)
  }, deps)
}

export default useTimeout

/* Referência: https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks */
