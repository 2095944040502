const INITIAL_STATE = {
  statusModalProfileOrganization: false,
  occupationList: [],
  occupationListIsLoading: true,
  timezoneList: [],
  timezoneListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_PROFILE_ORGANIZATION_FETCHED':
      return { ...state, statusModalProfileOrganization: INITIAL_STATE.statusModalProfileOrganization };
    case 'OPEN_MODAL_PROFILE_ORGANIZATION_FETCHED':
      return { ...state, statusModalProfileOrganization: action.payload };

    case 'CLEAN_PROFILE_ORGANIZATION_OCCUPATION_LIST_FETCHED':
      return { ...state, occupationList: INITIAL_STATE.occupationList, occupationListIsLoading: true };
    case 'PROFILE_ORGANIZATION_OCCUPATION_LIST_FETCHED':
      return { ...state, occupationList: action.payload, occupationListIsLoading: false };

    case 'CLEAN_PROFILE_ORGANIZATION_TIMEZONE_LIST_FETCHED':
      return { ...state, timezoneList: INITIAL_STATE.timezoneList, timezoneListIsLoading: true };
    case 'PROFILE_ORGANIZATION_TIMEZONE_LIST_FETCHED':
      return { ...state, timezoneList: action.payload, timezoneListIsLoading: false };
    default:
      return state;
  }
};
