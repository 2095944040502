const INITIAL_STATE = {
  statusModalCampaignDashboardCsv: false,
  campaignDashboardCsvList: '',
  campaignDashboardCsvListIsLoading: true,
  campaignDashboardCsvConsolidated: '',
  campaignDashboardCsvConsolidatedIsLoading: true,
  campaignDashboardCsvExported: '',
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_CAMPAIGN_DASHBOARD_CSV_FETCHED':
      return { ...state, statusModalCampaignDashboardCsv: INITIAL_STATE.statusModalCampaignDashboardCsv };
    case 'OPEN_MODAL_CAMPAIGN_DASHBOARD_CSV_FETCHED':
      return { ...state, statusModalCampaignDashboardCsv: action.payload };
    case 'CLEAN_MODAL_CAMPAIGN_DASHBOARD_CSV_LIST_FETCHED':
      return { ...state, campaignDashboardCsvList: INITIAL_STATE.campaignDashboardCsvList, campaignDashboardCsvListIsLoading: true };
    case 'MODAL_CAMPAIGN_DASHBOARD_CSV_LIST_FETCHED':
      return { ...state, campaignDashboardCsvList: action.payload, campaignDashboardCsvListIsLoading: false };
    case 'CLEAN_MODAL_CAMPAIGN_DASHBOARD_CSV_CONSOLIDATED_FETCHED':
      return { ...state, campaignDashboardCsvConsolidated: INITIAL_STATE.campaignDashboardCsvConsolidated, campaignDashboardCsvConsolidatedIsLoading: true };
    case 'MODAL_CAMPAIGN_DASHBOARD_CSV_CONSOLIDATED_FETCHED':
      return { ...state, campaignDashboardCsvConsolidated: action.payload, campaignDashboardCsvConsolidatedIsLoading: false };

    case 'MODAL_CAMPAIGN_DASHBOARD_CSV_EXPORTED_FETCHED':
      return { ...state, campaignDashboardCsvExported: action.payload };
    default:
      return state;
  }
};
