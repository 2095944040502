const INITIAL_STATE = {
  statusModalCampaignPreview: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_CAMPAIGN_PREVIEW_FETCHED':
      return { ...state, statusModalCampaignPreview: INITIAL_STATE.statusModalCampaignPreview };
    case 'OPEN_MODAL_CAMPAIGN_PREVIEW_FETCHED':
      return { ...state, statusModalCampaignPreview: action.payload };

    default:
      return state;
  }
};
