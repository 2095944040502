const INITIAL_STATE = {
  statusModalAutomationSmsDashboard: false,
  automationList: {},
  automationListIsLoading: true,
  performance: {
    answered: 0,
    pending: 0,
    delivered: 0,
    rejected: 0,
    answered_percentage: 0,
    pending_percentage: 0,
    delivered_percentage: 0,
    rejected_percentage: 0,
    id: 0,
  },
  performanceIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_AUTOMATION_SMS_DASHBOARD_FETCHED':
      return { ...state, statusModalAutomationSmsDashboard: INITIAL_STATE.statusModalAutomationSmsDashboard };
    case 'OPEN_MODAL_AUTOMATION_SMS_DASHBOARD_FETCHED':
      return { ...state, statusModalAutomationSmsDashboard: action.payload };

    case 'CLEAN_MODAL_AUTOMATION_SMS_DASHBOARD_LIST_FETCHED':
      return { ...state, automationList: INITIAL_STATE.automationList, automationListIsLoading: true };
    case 'MODAL_AUTOMATION_SMS_DASHBOARD_LIST_FETCHED':
      return { ...state, automationList: action.payload, automationListIsLoading: false };


    case 'CLEAN_MODAL_AUTOMATION_SMS_DASHBOARD_PERFORMANCE_FETCHED':
      return { ...state, performance: INITIAL_STATE.performance, performanceIsLoading: true };
    case 'MODAL_AUTOMATION_SMS_DASHBOARD_PERFORMANCE_FETCHED':
      return { ...state, performance: action.payload, performanceIsLoading: false };

    default:
      return state;
  }
};
