import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  automationList: '',
  automationListIsLoading: true,

  automationSummaryEmail: '',
  automationSummaryEmailIsLoading: true,

  automationSummarySms: '',
  automationSummarySmsIsLoading: true,

  automationSummaryWhatsapp: '',
  automationSummaryWhatsappIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.campaignDashboard };
      case 'CLEAN_AUTOMATION_LIST_FETCHED':
        return { ...state, automationList: INITIAL_STATE.automationList, automationListIsLoading: true };
      case 'AUTOMATION_LIST_FETCHED':
        return { ...state, automationList: action.payload, automationListIsLoading: false };

      case 'CLEAN_AUTOMATION_SUMMARY_EMAIL_FETCHED':
        return { ...state, automationSummaryEmail: INITIAL_STATE.automationSummaryEmail, automationSummaryEmailIsLoading: true };
      case 'AUTOMATION_SUMMARY_EMAIL_FETCHED':
        return { ...state, automationSummaryEmail: action.payload, automationSummaryEmailIsLoading: false };

        case 'CLEAN_AUTOMATION_SUMMARY_SMS_FETCHED':
        return { ...state, automationSummarySms: INITIAL_STATE.automationSummarySms, automationSummarySmsIsLoading: true };
      case 'AUTOMATION_SUMMARY_SMS_FETCHED':
        return { ...state, automationSummarySms: action.payload, automationSummarySmsIsLoading: false };

        case 'CLEAN_AUTOMATION_SUMMARY_WHATSAPP_FETCHED':
        return { ...state, automationSummaryWhatsapp: INITIAL_STATE.automationSummaryWhatsapp, automationSummaryWhatsappIsLoading: true };
      case 'AUTOMATION_SUMMARY_WHATSAPP_FETCHED':
        return { ...state, automationSummaryWhatsapp: action.payload, automationSummaryWhatsappIsLoading: false };

    default:
      return state;
  }
};
