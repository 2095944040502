const INITIAL_STATE = {
  campaignScheduledList: [],
  campaignScheduledListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_CAMPAIGN_SCHEDULED_LIST_FETCHED':
      return { ...state, campaignScheduledList: INITIAL_STATE.campaignScheduledList, campaignScheduledListIsLoading: true };
    case 'CAMPAIGN_SCHEDULED_LIST_FETCHED':
      return { ...state, campaignScheduledList: action.payload, campaignScheduledListIsLoading: false };
    default:
      return state;
  }
};
