import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  campaignList: '',
  campaignListIsLoading: true,
  campaignDashboardList: '',
  campaignDashboardListIsLoading: true,
  campaignDashboardActivities: '',
  campaignDashboardActivitiesIsLoading: true,
  campaignDashboardLocalization: '',
  campaignDashboardLocalizationIsLoading: true,
  campaignDashboardDevices: '',
  campaignDashboardDevicesIsLoading: true,
  campaignDashboardLinks: '',
  campaignDashboardLinksIsLoading: true,
  campaignDashboardTimeline: '',
  campaignDashboardTimelineIsLoading: true,
  campaignDashboardSpam: '',
  campaignDashboardSpamIsLoading: true,
  campaignDashboardOpenContact: '',
  campaignDashboardOpenContactIsLoading: true,
  campaignDashboardOpenCountry: '',
  campaignDashboardOpenCountryIsLoading: true,
  campaignDashboardDomain: '',
  campaignDashboardDomainIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.campaignDashboard };
      case 'CLEAN_CAMPAIGN_LIST_FETCHED':
        return { ...state, campaignList: INITIAL_STATE.campaignList, campaignListIsLoading: true };
      case 'CAMPAIGN_LIST_FETCHED':
        return { ...state, campaignList: action.payload, campaignListIsLoading: false };
      case 'CLEAN_CAMPAIGN_DASHBOARD_LIST_FETCHED':
        return { ...state, campaignDashboardList: INITIAL_STATE.campaignDashboardList, campaignDashboardListIsLoading: true };
      case 'CAMPAIGN_DASHBOARD_LIST_FETCHED':
        return { ...state, campaignDashboardList: action.payload, campaignDashboardListIsLoading: false };
      case 'CLEAN_CAMPAIGN_DASHBOARD_ACTIVITIES_FETCHED':
        return { ...state, campaignDashboardActivities: INITIAL_STATE.campaignDashboardActivities, campaignDashboardActivitiesIsLoading: true };
      case 'CAMPAIGN_DASHBOARD_ACTIVITIES_FETCHED':
        return { ...state, campaignDashboardActivities: action.payload, campaignDashboardActivitiesIsLoading: false };
      case 'CLEAN_CAMPAIGN_DASHBOARD_LOCALIZATION_FETCHED':
        return { ...state, campaignDashboardLocalization: INITIAL_STATE.campaignDashboardLocalization, campaignDashboardLocalizationIsLoading: true };
      case 'CAMPAIGN_DASHBOARD_LOCALIZATION_FETCHED':
        return { ...state, campaignDashboardLocalization: action.payload, campaignDashboardLocalizationIsLoading: false };
      case 'CLEAN_CAMPAIGN_DASHBOARD_DEVICES_FETCHED':
        return { ...state, campaignDashboardDevices: INITIAL_STATE.campaignDashboardDevices, campaignDashboardDevicesIsLoading: true };
      case 'CAMPAIGN_DASHBOARD_DEVICES_FETCHED':
        return { ...state, campaignDashboardDevices: action.payload, campaignDashboardDevicesIsLoading: false };
      case 'CLEAN_CAMPAIGN_DASHBOARD_LINKS_FETCHED':
        return { ...state, campaignDashboardLinks: INITIAL_STATE.campaignDashboardLinks, campaignDashboardLinksIsLoading: true };
      case 'CAMPAIGN_DASHBOARD_LINKS_FETCHED':
        return { ...state, campaignDashboardLinks: action.payload, campaignDashboardLinksIsLoading: false };
      case 'CLEAN_CAMPAIGN_DASHBOARD_TIMELINE_FETCHED':
        return { ...state, campaignDashboardTimeline: INITIAL_STATE.campaignDashboardTimeline, campaignDashboardTimelineIsLoading: true };
      case 'CAMPAIGN_DASHBOARD_TIMELINE_FETCHED':
        return { ...state, campaignDashboardTimeline: action.payload, campaignDashboardTimelineIsLoading: false };
      case 'CLEAN_CAMPAIGN_DASHBOARD_SPAM_FETCHED':
        return { ...state, campaignDashboardSpam: INITIAL_STATE.campaignDashboardSpam, campaignDashboardSpamIsLoading: true };
      case 'CAMPAIGN_DASHBOARD_SPAM_FETCHED':
        return { ...state, campaignDashboardSpam: action.payload, campaignDashboardSpamIsLoading: false };
      case 'CLEAN_CAMPAIGN_DASHBOARD_OPEN_CONTACT_FETCHED':
        return { ...state, campaignDashboardOpenContact: INITIAL_STATE.campaignDashboardOpenContact, campaignDashboardOpenContactIsLoading: true };
      case 'CAMPAIGN_DASHBOARD_OPEN_CONTACT_FETCHED':
        return { ...state, campaignDashboardOpenContact: action.payload, campaignDashboardOpenContactIsLoading: false };
      case 'CLEAN_CAMPAIGN_DASHBOARD_OPEN_COUNTRY_FETCHED':
        return { ...state, campaignDashboardOpenCountry: INITIAL_STATE.campaignDashboardOpenCountry, campaignDashboardOpenCountryIsLoading: true };
      case 'CAMPAIGN_DASHBOARD_OPEN_COUNTRY_FETCHED':
        return { ...state, campaignDashboardOpenCountry: action.payload, campaignDashboardOpenCountryIsLoading: false };
      case 'CLEAN_CAMPAIGN_DASHBOARD_DOMAIN_FETCHED':
        return { ...state, campaignDashboardDomain: INITIAL_STATE.campaignDashboardDomain, campaignDashboardDomainIsLoading: true };
      case 'CAMPAIGN_DASHBOARD_DOMAIN_FETCHED':
        return { ...state, campaignDashboardDomain: action.payload, campaignDashboardDomainIsLoading: false };
    default:
      return state;
  }
};
