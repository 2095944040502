const INITIAL_STATE = {
  statusModalAutomationSmsDrilldown: false,
  modalAutomationSmsDrilldownList: {},
  modalAutomationSmsDrilldownListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_AUTOMATION_SMS_DRILLDOWN_FETCHED':
      return { ...state, statusModalAutomationSmsDrilldown: INITIAL_STATE.statusModalAutomationSmsDrilldown };
    case 'OPEN_MODAL_AUTOMATION_SMS_DRILLDOWN_FETCHED':
      return { ...state, statusModalAutomationSmsDrilldown: action.payload };

    case 'CLEAN_MODAL_AUTOMATION_SMS_DRILLDOWN_LIST_FETCHED':
      return { ...state, modalAutomationSmsDrilldownList: INITIAL_STATE.AutomationSmsDrilldownedList, modalAutomationSmsDrilldownListIsLoading: true };
    case 'MODAL_AUTOMATION_SMS_DRILLDOWN_LIST_FETCHED':
      return { ...state, modalAutomationSmsDrilldownList: action.payload, modalAutomationSmsDrilldownListIsLoading: false };

    default:
      return state;
  }
};
