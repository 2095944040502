const INITIAL_STATE = {
  templateMarketingList: {
    resultado: [],
    _meta: {
      current_page: 0,
      per_page: 50,
      total_items: 0,
      total_pages: 0,
    },
  },
  templateMarketingListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_TEMPLATE_MARKETING_LIST_FETCHED':
      return { ...state, templateMarketingList: INITIAL_STATE.templateMarketingList, templateMarketingListIsLoading: true };
    case 'TEMPLATE_MARKETING_LIST_FETCHED':
      return { ...state, templateMarketingList: action.payload, templateMarketingListIsLoading: false };

    default:
      return state;
  }
};
