const INITIAL_STATE = {
  statusModalLandingPageSubdomain: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_LANDING_PAGE_SUBDOMAIN_FETCHED':
      return { ...state, statusModalLandingPageSubdomain: INITIAL_STATE.statusModalLandingPageSubdomain };
    case 'OPEN_MODAL_LANDING_PAGE_SUBDOMAIN_FETCHED':
      return { ...state, statusModalLandingPageSubdomain: action.payload };

    default:
      return state;
  }
};
