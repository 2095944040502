import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  moduleStep: 'marketing',
  tagList: [],
  tagListIsLoadingt: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.template };
    case 'TEMPLATE_MODULE_STEP_FETCHED':
      return { ...state, moduleStep: action.payload };
    case 'CLEAN_TEMPLATE_TAG_LIST_FETCHED':
      return { ...state, tagList: INITIAL_STATE.tagList, tagListIsLoadingt: true };
    case 'TEMPLATE_TAG_LIST_FETCHED':
      return { ...state, tagList: action.payload, tagListIsLoadingt: false };
    default:
      return state;
  }
};
