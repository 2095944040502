const INITIAL_STATE = {
  statusModalSegmentExport: false,
  segmentExportList: '',
  segmentExportListIsLoading: true,
  segmentExportedList: '',
  segmentExportedListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_SEGMENT_EXPORT_FETCHED':
      return { ...state, statusModalSegmentExport: INITIAL_STATE.statusModalSegmentExport };
    case 'OPEN_MODAL_SEGMENT_EXPORT_FETCHED':
      return { ...state, statusModalSegmentExport: action.payload };

    case 'CLEAN_MODAL_SEGMENT_EXPORT_FETCHED':
      return { ...state, segmentExportList: INITIAL_STATE.segmentExportList, segmentExportListIsLoading: true };
    case 'MODAL_SEGMENT_EXPORT_FETCHED':
      return { ...state, segmentExportList: action.payload, segmentExportListIsLoading: false };

    case 'CLEAN_MODAL_SEGMENT_EXPORTED_FETCHED':
      return { ...state, segmentExportedList: INITIAL_STATE.segmentExportedList, segmentExportedListIsLoading: true };
    case 'MODAL_SEGMENT_EXPORTED_FETCHED':
      return { ...state, segmentExportedList: action.payload, segmentExportedListIsLoading: false };
    default:
      return state;
  }
};
