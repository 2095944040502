const INITIAL_STATE = {
  statusModalSegmentDelete: false,
  modalSegmentDeleteList: '',
  modalSegmentDeleteListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_SEGMENT_DELETE_FETCHED':
      return { ...state, statusModalSegmentDelete: INITIAL_STATE.statusModalSegmentDelete };
    case 'OPEN_MODAL_SEGMENT_DELETE_FETCHED':
      return { ...state, statusModalSegmentDelete: action.payload };
    case 'CLEAN_MODAL_SEGMENT_DELETE_LIST_FETCHED':
      return { ...state, modalSegmentDeleteList: INITIAL_STATE.modalSegmentDeleteList, modalSegmentDeleteListIsLoading: true };
    case 'MODAL_SEGMENT_DELETE_LIST_FETCHED':
      return { ...state, modalSegmentDeleteList: action.payload, modalSegmentDeleteListIsLoading: false };
    default:
      return state;
  }
};
