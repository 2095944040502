const INITIAL_STATE = {
  statusModalAutomationDisable: false,
  modalAutomationDisableList: '',
  modalAutomationDisableListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_AUTOMATION_DISABLE_FETCHED':
      return { ...state, statusModalAutomationDisable: INITIAL_STATE.statusModalAutomationDisable };
    case 'OPEN_MODAL_AUTOMATION_DISABLE_FETCHED':
      return { ...state, statusModalAutomationDisable: action.payload };
    case 'CLEAN_MODAL_AUTOMATION_DISABLE_LIST_FETCHED':
      return { ...state, modalAutomationDisableList: INITIAL_STATE.modalAutomationDisableList, modalAutomationDisableListIsLoading: true };
    case 'MODAL_AUTOMATION_DISABLE_LIST_FETCHED':
      return { ...state, modalAutomationDisableList: action.payload, modalAutomationDisableListIsLoading: false };
    default:
      return state;
  }
};
