const INITIAL_STATE = {
  statusModalBillingInformationMethod: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_BILLING_INFORMATION_METHOD_FETCHED':
      return { ...state, statusModalBillingInformationMethod: INITIAL_STATE.statusModalBillingInformationMethod };
    case 'OPEN_MODAL_BILLING_INFORMATION_METHOD_FETCHED':
      return { ...state, statusModalBillingInformationMethod: action.payload };
    default:
      return state;
  }
};
