import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  pageStep: 'registered',
  contactRegisteredList: [],
  contactRegisteredListIsLoading: true,
  contactUnregisteredList: [],
  contactUnregisteredListIsLoading: true,
  contactRejectedList: [],
  contactRejectedListIsLoading: true,
  contactValidatedList: {
    resultado: [],
    registros: {
      registros: 0,
      descartavel: 0,
      desconhecido: 0,
      dominioInvalido: 0,
      erroSintaxe: 0,
      incerto: 0,
      invalido: 0,
      junk: 0,
      limitado: 0,
      scraped: 0,
      spamtrapConfirmado: 0,
      spamtrapPotencial: 0,
      total: 0,
      valido: 0
    },
  },
  contactValidatedListIsLoading: true,
  safetyMailsCredentials: {
    domainScripts: [],
    massPassword: '',
    massToken: '',
    realTimePassword: '',
    realTimeToken: '',
  },
  safetyMailsCredentialsIsLoading: true,
  safetyMailsStatus: null,
  safetyMailsStatusIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.listContact };
    case 'CONTACT_PAGE_STEP_FETCHED':
      return { ...state, pageStep: action.payload };

    case 'CLEAN_CONTACTS_REGISTERED_LIST_FETCHED':
      return { ...state, contactRegisteredList: INITIAL_STATE.contactRegisteredList, contactRegisteredListIsLoading: true };
    case 'CONTACTS_REGISTERED_LIST_FETCHED':
      return { ...state, contactRegisteredList: action.payload, contactRegisteredListIsLoading: false };

    case 'CLEAN_CONTACTS_UNREGISTERED_LIST_FETCHED':
      return { ...state, contactUnregisteredList: INITIAL_STATE.contactUnregisteredList, contactUnregisteredListIsLoading: true };
    case 'CONTACTS_UNREGISTERED_LIST_FETCHED':
      return { ...state, contactUnregisteredList: action.payload, contactUnregisteredListIsLoading: false };

    case 'CLEAN_CONTACTS_REJECTED_LIST_FETCHED':
      return { ...state, contactRejectedList: INITIAL_STATE.contactRejectedList, contactRejectedListIsLoading: true };
    case 'CONTACTS_REJECTED_LIST_FETCHED':
      return { ...state, contactRejectedList: action.payload, contactRejectedListIsLoading: false };

    case 'CLEAN_CONTACTS_VALIDATED_LIST_FETCHED':
      return { ...state, contactValidatedList: INITIAL_STATE.contactValidatedList, contactValidatedListIsLoading: true };
    case 'CONTACTS_VALIDATED_LIST_FETCHED':
      return { ...state, contactValidatedList: action.payload, contactValidatedListIsLoading: false };

    case 'CLEAN_SAFETY_MAILS_CREDENTIALS_FETCHED':
      return { ...state, safetyMailsCredentials: INITIAL_STATE.safetyMailsCredentials, safetyMailsCredentialsIsLoading: true };
    case 'SAFETY_MAILS_CREDENTIALS_FETCHED':
      return { ...state, safetyMailsCredentials: action.payload, safetyMailsCredentialsIsLoading: false };

    case 'CLEAN_SAFETY_MAILS_STATUS_FETCHED':
      return { ...state, safetyMailsStatus: INITIAL_STATE.safetyMailsStatus, safetyMailsStatusIsLoading: true };
    case 'SAFETY_MAILS_STATUS_FETCHED':
      return { ...state, safetyMailsStatus: action.payload, safetyMailsStatusIsLoading: false };

    default:
      return state;
  }
};
