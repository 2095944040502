const INITIAL_STATE = {
  monthlyList: [],
  monthlyListIsLoading: true,
  invoicingList: '',
  invoicingListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_BILLING_PLAN_MONTHLY_LIST_FETCHED':
      return { ...state, monthlyList: INITIAL_STATE.monthlyList, monthlyListIsLoading: true };
    case 'BILLING_PLAN_MONTHLY_LIST_FETCHED':
      return { ...state, monthlyList: action.payload, monthlyListIsLoading: false };

    case 'CLEAN_BILLING_PLAN_INVOICING_LIST_FETCHED':
      return { ...state, invoicingList: INITIAL_STATE.invoicingList, invoicingListIsLoading: true };
    case 'BILLING_PLAN_INVOICING_LIST_FETCHED':
      return { ...state, invoicingList: action.payload, invoicingListIsLoading: false };

    default:
      return state;
  }
};
