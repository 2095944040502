const INITIAL_STATE = {
  statusModalSendTestEmail: false,
  modalSendTestEmailList: '',
  modalSendTestEmailListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_SEND_TEST_EMAIL_FETCHED':
      return { ...state, statusModalSendTestEmail: INITIAL_STATE.statusModalSendTestEmail };
    case 'OPEN_MODAL_SEND_TEST_EMAIL_FETCHED':
      return { ...state, statusModalSendTestEmail: action.payload };
    case 'CLEAN_MODAL_SEND_TEST_EMAIL_LIST_FETCHED':
      return { ...state, modalSendTestEmailList: INITIAL_STATE.TemplateSendTestedList, modalSendTestEmailListIsLoading: true };
    case 'MODAL_SEND_TEST_EMAIL_LIST_FETCHED':
      return { ...state, modalSendTestEmailList: action.payload, modalSendTestEmailListIsLoading: false };
    default:
      return state;
  }
};
