const INITIAL_STATE = {
  statusModalUserDelete: false,
  modalUserDeleteList: '',
  modalUserDeleteListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_USER_DELETE_FETCHED':
      return { ...state, statusModalUserDelete: INITIAL_STATE.statusModalUserDelete };
    case 'OPEN_MODAL_USER_DELETE_FETCHED':
      return { ...state, statusModalUserDelete: action.payload };

    case 'CLEAN_MODAL_USER_DELETE_LIST_FETCHED':
      return { ...state, modalUserDeleteList: INITIAL_STATE.modalUserDeleteList, modalUserDeleteListIsLoading: true };
    case 'MODAL_USER_DELETE_LIST_FETCHED':
      return { ...state, modalUserDeleteList: action.payload, modalUserDeleteListIsLoading: false };

    default:
      return state;
  }
};
