import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  pageStep: 'list',
  importList: {
    resultado: [],
    _meta: {
      current_page: 0,
      per_page: 50,
      total_items: 0,
      total_pages: 0,
    },
  },
  importListIsLoading: true,
  importDetailList: {
    status: {},
  },
  importDetailListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.listImport };
    case 'IMPORT_PAGE_STEP_FETCHED':
      return { ...state, pageStep: action.payload };
    case 'CLEAN_IMPORT_LIST_FETCHED':
      return { ...state, importList: INITIAL_STATE.importList, importListIsLoading: true };
    case 'IMPORT_LIST_FETCHED':
      return { ...state, importList: action.payload, importListIsLoading: false };
    case 'CLEAN_IMPORT_DETAIL_LIST_FETCHED':
      return { ...state, importDetailList: INITIAL_STATE.importDetailList, importDetailListIsLoading: true };
    case 'IMPORT_DETAIL_LIST_FETCHED':
      return { ...state, importDetailList: action.payload, importDetailListIsLoading: false };
    default:
      return state;
  }
};
