const INITIAL_STATE = {
  statusModalLandingPageDelete: false,
  modalLandingPageDeleteList: '',
  modalLandingPageDeleteListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_LANDING_PAGE_DELETE_FETCHED':
      return { ...state, statusModalLandingPageDelete: INITIAL_STATE.statusModalLandingPageDelete };
    case 'OPEN_MODAL_LANDING_PAGE_DELETE_FETCHED':
      return { ...state, statusModalLandingPageDelete: action.payload };
    case 'CLEAN_MODAL_LANDING_PAGE_DELETE_LIST_FETCHED':
      return { ...state, modalLandingPageDeleteList: INITIAL_STATE.LandingPageDeleteedList, modalLandingPageDeleteListIsLoading: true };
    case 'MODAL_LANDING_PAGE_DELETE_LIST_FETCHED':
      return { ...state, modalLandingPageDeleteList: action.payload, modalLandingPageDeleteListIsLoading: false };
    default:
      return state;
  }
};
