const INITIAL_STATE = {
  statusModalDomainPendingDmarcConfiguration: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_DOMAIN_PENDING_DMARC_CONFIGURATION_FETCHED':
      return { ...state, statusModalDomainPendingDmarcConfiguration: INITIAL_STATE.statusModalDomainPendingDmarcConfiguration };
    case 'OPEN_MODAL_DOMAIN_PENDING_DMARC_CONFIGURATION_FETCHED':
      return { ...state, statusModalDomainPendingDmarcConfiguration: action.payload };
    default:
      return state;
  }
};
