const INITIAL_STATE = {
  statusModalBillingHistoryUsageMore: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_BILLING_HISTORY_USAGE_MORE_FETCHED':
      return { ...state, statusModalBillingHistoryUsageMore: INITIAL_STATE.statusModalBillingHistoryUsageMore };
    case 'OPEN_MODAL_BILLING_HISTORY_USAGE_MORE_FETCHED':
      return { ...state, statusModalBillingHistoryUsageMore: action.payload };
    default:
      return state;
  }
};
