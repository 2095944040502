import React from 'react'
import NextHead from 'next/head'
import { withTranslation } from 'i18n'

const Head = ({ t }) => {
  return (
    <NextHead>
      <title>{t('page')}</title>

      {/* Chat Movidesk - em _app, adiciona no login e remove no logout */}
      <script
        type='text/javascript'
        dangerouslySetInnerHTML={{
          __html: `var mdChatClient="D659DDAC7E15436DB59E4529DECAF011";`,
        }}
      />
    </NextHead>
  )
}

Head.getInitialProps = async (ctx) => {
  return { namespacesRequired: ['common'] }
}

export default withTranslation('common')(Head)
