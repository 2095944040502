const INITIAL_STATE = {
  statusModalCampaignSend: false,
  modalCampaignSendList: '',
  modalCampaignSendListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_CAMPAIGN_SEND_FETCHED':
      return { ...state, statusModalCampaignSend: INITIAL_STATE.statusModalCampaignSend };
    case 'OPEN_MODAL_CAMPAIGN_SEND_FETCHED':
      return { ...state, statusModalCampaignSend: action.payload };
    case 'CLEAN_MODAL_CAMPAIGN_SEND_LIST_FETCHED':
      return { ...state, modalCampaignSendList: INITIAL_STATE.modalCampaignSendList, modalCampaignSendListIsLoading: true };
    case 'MODAL_CAMPAIGN_SEND_LIST_FETCHED':
      return { ...state, modalCampaignSendList: action.payload, modalCampaignSendListIsLoading: false };
    default:
      return state;
  }
};
