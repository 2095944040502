const INITIAL_STATE = {
  statusModalListContactDelete: false,
  modalListContactDeleteList: '',
  modalListContactDeleteListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_LIST_CONTACT_DELETE_FETCHED':
      return { ...state, statusModalListContactDelete: INITIAL_STATE.statusModalListContactDelete };
    case 'OPEN_MODAL_LIST_CONTACT_DELETE_FETCHED':
      return { ...state, statusModalListContactDelete: action.payload };
    case 'CLEAN_MODAL_LIST_CONTACT_DELETE_LIST_FETCHED':
      return { ...state, modalListContactDeleteList: INITIAL_STATE.modalListContactDeleteList, modalListContactDeleteListIsLoading: true };
    case 'MODAL_LIST_CONTACT_DELETE_LIST_FETCHED':
      return { ...state, modalListContactDeleteList: action.payload, modalListContactDeleteListIsLoading: false };
    default:
      return state;
  }
};
