const INITIAL_STATE = {
  statusModalProfileOrganizationBackup: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_PROFILE_ORGANIZATION_BACKUP_FETCHED':
      return { ...state, statusModalProfileOrganizationBackup: INITIAL_STATE.statusModalProfileOrganizationBackup };
    case 'OPEN_MODAL_PROFILE_ORGANIZATION_BACKUP_FETCHED':
      return { ...state, statusModalProfileOrganizationBackup: action.payload };
    default:
      return state;
  }
};
