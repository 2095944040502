import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  moduleStep: 'list',
  domainList: {
    resultado: [],
    _meta: {
      current_page: 0,
      per_page: 50,
      total_items: 0,
      total_pages: 0,
    },
  },
  domainListIsLoading: true,
  domainItem: '',
  domainItemIsLoading: true,
  domainDns: '',
  domainDnsIsLoading: true,
  domainVerify: '',
  domainVeryfyIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.domain };
    case 'DOMAIN_MODULE_STEP_FETCHED':
      return { ...state, moduleStep: action.payload, pageStepIsLoadingt: false };

    case 'CLEAN_DOMAIN_LIST_FETCHED':
      return { ...state, domainList: INITIAL_STATE.domainList, domainListIsLoading: true };
    case 'DOMAIN_LIST_FETCHED':
      return { ...state, domainList: action.payload, domainListIsLoading: false };

    case 'CLEAN_DOMAIN_ITEM_FETCHED':
      return { ...state, domainItem: INITIAL_STATE.domainItem, domainItemIsLoading: true };
    case 'DOMAIN_ITEM_FETCHED':
      return { ...state, domainItem: action.payload, domainItemIsLoading: false };

    case 'CLEAN_DOMAIN_DNS_FETCHED':
      return { ...state, domainDns: INITIAL_STATE.domainDns, domainDnsIsLoading: true };
    case 'DOMAIN_DNS_FETCHED':
      return { ...state, domainDns: action.payload, domainDnsIsLoading: false };

    case 'CLEAN_DOMAIN_VERIFY_FETCHED':
      return { ...state, domainVerify: INITIAL_STATE.domainVerify, domainVeryfyIsLoading: true };
    case 'DOMAIN_VERIFY_FETCHED':
      return { ...state, domainVerify: action.payload, domainVeryfyIsLoading: false };
    default:
      return state;
  }
};
