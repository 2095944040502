const INITIAL_STATE = {
  statusModalTemplateDelete: false,
  modalTemplateDeleteList: '',
  modalTemplateDeleteListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_TEMPLATE_DELETE_FETCHED':
      return { ...state, statusModalTemplateDelete: INITIAL_STATE.statusModalTemplateDelete };
    case 'OPEN_MODAL_TEMPLATE_DELETE_FETCHED':
      return { ...state, statusModalTemplateDelete: action.payload };
    case 'CLEAN_MODAL_TEMPLATE_DELETE_LIST_FETCHED':
      return { ...state, modalTemplateDeleteList: INITIAL_STATE.TemplateDeleteedList, modalTemplateDeleteListIsLoading: true };
    case 'MODAL_TEMPLATE_DELETE_LIST_FETCHED':
      return { ...state, modalTemplateDeleteList: action.payload, modalTemplateDeleteListIsLoading: false };
    default:
      return state;
  }
};
