const INITIAL_STATE = {
  campaignCompletedList: [],
  campaignCompletedListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAN_CAMPAIGN_COMPLETED_LIST_FETCHED':
      return { ...state, campaignCompletedList: INITIAL_STATE.campaignCompletedList, campaignCompletedListIsLoading: true };
    case 'CAMPAIGN_COMPLETED_LIST_FETCHED':
      return { ...state, campaignCompletedList: action.payload, campaignCompletedListIsLoading: false };
    default:
      return state;
  }
};
