const INITIAL_STATE = {
  statusModalLandingPageAutoUnpublish: false,
  modalLandingPageAutoUnpublishList: '',
  modalLandingPageAutoUnpublishListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_LANDING_PAGE_AUTO_UNPUBLISHED_FETCHED':
      return { ...state, statusModalLandingPageAutoUnpublish: INITIAL_STATE.statusModalLandingPageAutoUnpublish };
    case 'OPEN_MODAL_LANDING_PAGE_AUTO_UNPUBLISHED_FETCHED':
      return { ...state, statusModalLandingPageAutoUnpublish: action.payload };
    case 'CLEAN_MODAL_LANDING_PAGE_AUTO_UNPUBLISHED_LIST_FETCHED':
      return { ...state, modalLandingPageAutoUnpublishList: INITIAL_STATE.landingPageAutoUnpublishedList, modalLandingPageAutoUnpublishListIsLoading: true };
    case 'MODAL_LANDING_PAGE_AUTO_UNPUBLISHED_LIST_FETCHED':
      return { ...state, modalLandingPageAutoUnpublishList: action.payload, modalLandingPageAutoUnpublishListIsLoading: false };
    default:
      return state;
  }
};
