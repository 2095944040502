import { HYDRATE } from "next-redux-wrapper";

const INITIAL_STATE = {
  integrationSyonetCrmLogList: {
    resultado: [],
    _meta: {
      current_page: 0,
      per_page: 50,
      total_items: 0,
      total_pages: 0,
    },
  },
  integrationSyonetCrmLogListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HYDRATE:
      return { ...state, ...action.payload.integration_crm };

    case 'CLEAN_INTEGRATION_CRM_LOG_LIST_FETCHED':
      return { ...state, integrationSyonetCrmLogList: INITIAL_STATE.integrationSyonetCrmLogList, integrationSyonetCrmLogListIsLoading: true };
    case 'INTEGRATION_CRM_LOG_LIST_FETCHED':
      return { ...state, integrationSyonetCrmLogList: action.payload, integrationSyonetCrmLogListIsLoading: false };

    default:
      return state;
  }
};
