const INITIAL_STATE = {
  statusModalAutomationConclude: false,
  modalAutomationConcludeList: '',
  modalAutomationConcludeListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_AUTOMATION_CONCLUDE_FETCHED':
      return { ...state, statusModalAutomationConclude: INITIAL_STATE.statusModalAutomationConclude };
    case 'OPEN_MODAL_AUTOMATION_CONCLUDE_FETCHED':
      return { ...state, statusModalAutomationConclude: action.payload };
    case 'CLEAN_MODAL_AUTOMATION_CONCLUDE_LIST_FETCHED':
      return { ...state, modalAutomationConcludeList: INITIAL_STATE.modalAutomationConcludeList, modalAutomationConcludeListIsLoading: true };
    case 'MODAL_AUTOMATION_CONCLUDE_LIST_FETCHED':
      return { ...state, modalAutomationConcludeList: action.payload, modalAutomationConcludeListIsLoading: false };
    default:
      return state;
  }
};
