const INITIAL_STATE = {
  statusModalProfileUserPassword: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_PROFILE_USER_PASSWORD_FETCHED':
      return { ...state, statusModalProfileUserPassword: INITIAL_STATE.statusModalProfileUserPassword };
    case 'OPEN_MODAL_PROFILE_USER_PASSWORD_FETCHED':
      return { ...state, statusModalProfileUserPassword: action.payload };
    default:
      return state;
  }
};
