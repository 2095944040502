const INITIAL_STATE = {
  statusModalUser: false,
  modalUserOriginList: [],
  modalUserOriginListIsLoading: true,
  modalUserListList: [],
  modalUserListListIsLoading: true,
  modalUserPerformanceList: '',
  modalUserPerformanceListIsLoading: true,
  modalUserLocationList: [],
  modalUserLocationListIsLoading: true,
  modalUserVariablesList: [],
  modalUserVariablesListIsLoading: true,
  modalUserScoreList: [],
  modalUserScoreListIsLoading: true,
  modalUserActivitiesList: [],
  modalUserActivitiesListIsLoading: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLOSE_MODAL_USER_FETCHED':
      return { ...state, statusModalUser: INITIAL_STATE.statusModalUser };
    case 'OPEN_MODAL_USER_FETCHED':
      return { ...state, statusModalUser: action.payload };
    case 'CLEAN_MODAL_USER_ORIGIN_FETCHED':
      return { ...state, modalUserOriginList: INITIAL_STATE.modalUserOriginList, modalUserOriginListIsLoading: true };
    case 'MODAL_USER_ORIGIN_FETCHED':
      return { ...state, modalUserOriginList: action.payload, modalUserOriginListIsLoading: false };
    case 'CLEAN_MODAL_USER_LIST_LIST_FETCHED':
      return { ...state, modalUserListList: INITIAL_STATE.modalUserListList, modalUserListListIsLoading: true };
    case 'MODAL_USER_LIST_LIST_FETCHED':
      return { ...state, modalUserListList: action.payload, modalUserListListIsLoading: false };
    case 'CLEAN_MODAL_USER_PERFORMANCE_LIST_FETCHED':
      return { ...state, modalUserPerformanceList: INITIAL_STATE.modalUserPerformanceList, modalUserPerformanceListIsLoading: true };
    case 'MODAL_USER_PERFORMANCE_LIST_FETCHED':
      return { ...state, modalUserPerformanceList: action.payload, modalUserPerformanceListIsLoading: false };
    case 'CLEAN_MODAL_USER_LOCATION_LIST_FETCHED':
      return { ...state, modalUserLocationList: INITIAL_STATE.modalUserLocationList, modalUserLocationListIsLoading: true };
    case 'MODAL_USER_LOCATION_LIST_FETCHED':
      return { ...state, modalUserLocationList: action.payload, modalUserLocationListIsLoading: false };
    case 'CLEAN_MODAL_USER_VARIABLES_LIST_FETCHED':
      return { ...state, modalUserVariablesList: INITIAL_STATE.modalUserVariablesList, modalUserVariablesListIsLoading: true };
    case 'MODAL_USER_VARIABLES_LIST_FETCHED':
      return { ...state, modalUserVariablesList: action.payload, modalUserVariablesListIsLoading: false };
    case 'CLEAN_MODAL_USER_SCORE_LIST_FETCHED':
      return { ...state, modalUserScoreList: INITIAL_STATE.modalUserScoreList, modalUserScoreListIsLoading: true };
    case 'MODAL_USER_SCORE_LIST_FETCHED':
      return { ...state, modalUserScoreList: action.payload, modalUserScoreListIsLoading: false };
    case 'CLEAN_MODAL_USER_ACTIVITIES_LIST_FETCHED':
      return { ...state, modalUserActivitiesList: INITIAL_STATE.modalUserActivitiesList, modalUserActivitiesListIsLoading: true };
    case 'MODAL_USER_ACTIVITIES_LIST_FETCHED':
      return { ...state, modalUserActivitiesList: action.payload, modalUserActivitiesListIsLoading: false };
    default:
      return state;
  }
};



